<div id="printBillRefunds">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-info-circle"></i> &nbsp; {{'BillRefundsInfo' | translate}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-lg-12" *ngIf="Bill">
        <div class="text-start py-4">

          <div class="formCard">
            <table class="table table-sm">
              <tr>
                <td>{{ 'BillCode' | translate }}</td>
                <td>{{ Bill.TransactionCodeText }}</td>
              </tr>
              <tr>
                <td>{{ 'ClientName' | translate }}</td>
                <td>{{ Bill.ClientName }}</td>
              </tr>
              <tr>
                <td>{{ 'ClientMobile' | translate }}</td>
                <td>{{ Bill.ClientMobile }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <h5> <i class="fas fa-exchange"></i> &nbsp; {{ 'BillRefunds' | translate }} </h5>
      </div>

      <div class="col-md-12" *ngIf="(!Loading && BillRefunds); let BillRefunds; else loading">
        <div class="table-card">
          <table class="table table-stripedd table-sm table-bordered">
            <thead class="table-head">
              <tr style="color: black; font-size: large;">
                <th class="formatTd text-center">{{ "Index" | translate }}</th>
                <th class="formatTd text-center"> {{ 'RefundCode' | translate }}</th>
                <th class="formatTd text-center">{{ 'RefundDate' | translate }}</th>
                <th class="formatTd text-center">{{ 'ReturnedBy' | translate }}</th>
                <th class="formatTd text-center">{{ 'ReturnedAmount' | translate }}</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let billRefund of BillRefunds; let i = index">

                <td class="formatTd text-center"> {{ i + 1 }}</td>
                <td class="formatTd text-center">{{ billRefund.RefundCode | translate}}</td>
                <td class="formatTd text-center">{{ billRefund.Date | date: 'yyyy-MM-dd hh:mm a'}}</td>
                <td class="formatTd text-center">{{ billRefund.ReturnedBy}}</td>

                <td class="formatTd text-center">{{
                  billRefund.ReturnedAmount.toFixed(sharedService.GetFractionRoundCount) }}
                  <span>
                    &nbsp;
                    {{sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                    sharedService.CurrencyNameEn}}</span>
                </td>

              </tr>


            </tbody>
          </table>
          <div class="text-center py-4 my-4" *ngIf="BillRefunds.length == 0">
            <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
          </div>
        </div>
      </div>

      <!--  <div class="col-md-12" [hidden]="!BillRefunds">
        <app-pagination (GetSources)="GetBillRefunds()" #pager></app-pagination>
      </div> -->

      <div class="col-md-12">
        <ng-template #loading>
          <div class="col-md-12">
            <app-loading></app-loading>
          </div>
        </ng-template>
      </div>

      <div class="col-lg-12" *ngIf="false">
        <div class="col-md-12 text-center py-4 my-4">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <h1>{{'Loading' | translate}} </h1>
        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
