import { ResourceManagment } from '../models/classes/resource/resource-managment';
import { AccountSetup } from '../models/classes/account-setup/account-setup';

export class CustomTime {
  constructor() {
    this.customCalendeValues = [];
  }
  public Text: string;
  public Value: string;
  public EmployeeId: number;
  public BookingDate: any;
  public BookingForNextDay: boolean;

  public customCalendeValues: CustomCalendeValue[];
}
export class CustomCalendeValue {
  constructor() {
    this.IsBooked = false;
    this.IsOutOfService = false;
    // this.ServiceNames = [];
    this.ServiceNamesAr = [];
    this.ServiceNamesEn = [];
    this.IsOff = false;
    this.HasEquipments = false;
    this.HasRooms = false;
  }
  public IsOff: boolean;
  public IsOutOfService: boolean;
  public Id: number;
  public IsBooked: boolean;
  public headerDate: Date;
  public EmployeeId: number;
  public TimeValue: string;
  public TimeText: string;

  public ClientName: string;
  public ClientPhone: string;
  public ClientWorkNumber: string;
  public ClientMobile: string;
  public color: string;
  public SlotId: number;
  public Comment: string;
  public TotalTime: number;
  public SlotsNo: number;
  // public ServiceNames: string[];
  public ServiceNamesAr: string[];
  public ServiceNamesEn: string[];
  public HasEquipments: boolean;
  public Equipments: ResourceManagment[];
  public EquipmentsDesc: string;
  public HasRooms: boolean;
  public Rooms: ResourceManagment[];
  public RoomsDesc: string;
  public ResourceManagmentSlotId: number;
  public AppearOnSearch: boolean;
  public BookingStatus: number;
  public BookingForNextDay: boolean;
}
export function getTimeSlots(
  nHours: number,
  slotByMinute: number,
  startingHour?: number
): CustomTime[] {
  let nSlots = nHours * (60 / slotByMinute);
  let times = [];
  var d = setStartTime(new Date(), startingHour);
  for (var i = 0; i < nSlots; i++) {
    var customTime = new CustomTime();
    customTime.Value = getTimeString(d);
    customTime.Text = getTimeFormated(d);
    times.push(customTime);
    d.setMinutes(d.getMinutes() + slotByMinute);
  }
  return times;
}
export function urlExistInNavigationModel(model: any, url: string) {
  if (model == undefined) return false;
  let _subItem = null;

  for (let index = 0; index < model.length; index++) {
    let modelEl = model[index];
    for (let k = 0; k < modelEl.menuContent.length; k++) {
      _subItem = modelEl.menuContent[k].subItem.filter((p) => p.url == url);
      if (_subItem.length > 0) {
        return true;
      }
    }
  }
  return false;
}

export function getTimeBreaks(
  nHours: number,
  BreakByMinute: number,
  startingTime: string,
  d: Date
): CustomTime[] {
  var sentDate = new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds()
  );

  let nSlots = nHours * (60 / BreakByMinute);
  let times = [];
  // var d = setStartTime(new Date(), 0);
  sentDate.setHours(Number(startingTime.split(':')[0]));
  sentDate.setMinutes(Number(startingTime.split(':')[1]));
  sentDate.setSeconds(Number(startingTime.split(':')[2]));
  for (var i = 0; i < nSlots; i++) {
    var customTime = new CustomTime();
    customTime.Value = getTimeString(sentDate);
    customTime.Text = getTimeFormated(sentDate);
    //customTime.Text = customTime.Value;
    if (
      sentDate.getDay() != d.getDay() ||
      (sentDate.getHours() > 0 && sentDate.getHours() < 5)
    )
      customTime.BookingForNextDay = true;
    else customTime.BookingForNextDay = false;

    times.push(customTime);
    sentDate.setMinutes(sentDate.getMinutes() + BreakByMinute);
  }
  return times;
}
export function getTimeString(d: Date): string {
  var hr =
    d.getHours() > 9 ? d.getHours().toString() : '0' + d.getHours().toString();
  var min =
    d.getMinutes() > 9
      ? d.getMinutes().toString()
      : '0' + d.getMinutes().toString();
  var sec =
    d.getSeconds() > 9
      ? d.getSeconds().toString()
      : '0' + d.getSeconds().toString();
  return hr + ':' + min + ':' + sec;
}
function setStartTime(value: Date, startingHour: number = 7) {
  value.setHours(startingHour);
  value.setMinutes(0);
  value.setSeconds(0);
  return value;
}

export function daysIntoYear(date) {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
}

export function dateFromDay(day) {
  var date = new Date(2019, 0); // initialize a date in `year-01-01`
  return new Date(date.setDate(day)); // add the number of days
}

export function getArrayRange(start: number, end: number) {
  let res = [];
  for (let i = start; i <= end; i++) res.push(i);
  return res;
}

export const getDays = (monthIndex) => {
  let numberOfDayes = daysInMonth(monthIndex);
  return getArrayRange(1, numberOfDayes);
};

export function daysInMonth(month) {
  return new Date(2019, +month + 1, 0).getDate();
}
function getTimeFormated(value: Date) {
  var tempMinutes =
    value.getMinutes() == 0 ? '00' : value.getMinutes().toString();
  var tempHours =
    value.getHours() > 12 ? value.getHours() - 12 : value.getHours();
  var tempStrHours = tempHours > 9 ? tempHours.toString() : '0' + tempHours;

  // if (value.getHours() > 12) {
  if (!(value.getHours() < 12)) {
    return tempStrHours + ':' + tempMinutes + ' pm';
  } else {
    return tempStrHours + ':' + tempMinutes + ' am';
  }
}

export function getMaxWorkingEndHour(accountSetup: AccountSetup): number {
  var _workingHours =
    accountSetup != null
      ? accountSetup.WorkingHours.filter((c) => c.Date == null)
      : undefined;
  _workingHours = _workingHours.filter((c) => c.DayOff != true);

  let maxHour = '';
  if (accountSetup && _workingHours.length != 0 && _workingHours != undefined) {
    maxHour = _workingHours[0].FinishTime;
    _workingHours.forEach((wrkHour) => {
      if (wrkHour.FinishTime < wrkHour.StartTime) {
        var hr = Number(wrkHour.FinishTime.split(':')[0]) + 24;
        var min = Number(wrkHour.FinishTime.split(':')[1]);
        var sec = Number(wrkHour.FinishTime.split(':')[2]);
        var hour = hr > 9 ? hr.toString() : '0' + hr.toString();
        var minute = min > 9 ? min.toString() : '0' + min.toString();
        var second = sec > 9 ? sec.toString() : '0' + sec.toString();
        wrkHour.FinishTime = hour + ':' + minute + ':' + second;
      }
      if (maxHour < wrkHour.FinishTime) maxHour = wrkHour.FinishTime;
    });
  }

  if (maxHour != null) {
    if (+maxHour.substring(3, 5) != 0) {
      let xx = maxHour == null ? 0 : +maxHour.substring(0, 2);
      return +xx + 1;
    }
    return maxHour == null ? 0 : +maxHour.substring(0, 2);
  }
}

export function getMinWorkingStartHour(accountSetup: AccountSetup): number {
  // alert(accountSetup)

  var _workingHours =
    accountSetup != null
      ? accountSetup.WorkingHours.filter((c) => c.Date == null)
      : undefined;

  _workingHours = _workingHours.filter((c) => c.DayOff != true);
  //
  let minHour = '24';
  if (accountSetup && _workingHours.length != 0 && _workingHours != undefined) {
    minHour = _workingHours[0].StartTime;
    _workingHours.forEach((wrkHour) => {
      if (minHour > wrkHour.StartTime) minHour = wrkHour.StartTime;
    });
  }
  return minHour == null ? 0 : +minHour.substring(0, 2);
}
export function getDayTextAlias(
  dayNum,
  startSubString,
  endSubString,
  isArabicLanguage: boolean
) {
  let dayInWeek;
  switch (dayNum) {
    case 0:
      dayInWeek = isArabicLanguage
        ? 'Sunday'.substring(startSubString, endSubString)
        : 'الاحد';
      break;
    case 1:
      dayInWeek = isArabicLanguage
        ? 'Monday'.substring(startSubString, endSubString)
        : 'الاثنين';
      break;
    case 2:
      dayInWeek = isArabicLanguage
        ? 'Tuesday'.substring(startSubString, endSubString)
        : 'الثلاثاء';
      break;
    case 3:
      dayInWeek = isArabicLanguage
        ? 'Wednesday'.substring(startSubString, endSubString)
        : 'الاربعاء';
      break;
    case 4:
      dayInWeek = isArabicLanguage
        ? 'Thursday'.substring(startSubString, endSubString)
        : 'الخميس';
      break;
    case 5:
      dayInWeek = isArabicLanguage
        ? 'Friday'.substring(startSubString, endSubString)
        : 'الجمعه';
      break;
    case 6:
      dayInWeek = isArabicLanguage
        ? 'Saturday'.substring(startSubString, endSubString)
        : 'السبت';
      break;
  }
  return dayInWeek;
}
export function UXErrors(res: any): string {
  var mess = '';

  if (res && res.error && res.error.Message) {
    mess = mess + res.error.Message + '\n';
    if (res.error.ModelState && Object.keys(res.error.ModelState).length > 0) {
      for (let key of Object.keys(res.error.ModelState)) {
        for (let err of res.error.ModelState[key]) {
          mess = mess + err + '\n';
        }
      }
    }
    //  return  message string
    return mess;
  }

  if (res && res.Exception && res.Exception.Message) {
    mess = mess + res.Exception.Message + '\n';
    return mess;
  }
  return JSON.stringify(res);
}

export function getDateObj(oldDate: any, time?: any): any {
  var _datetime = null;
  if (
    !oldDate ||
    !oldDate.year ||
    oldDate.year == undefined ||
    oldDate.year == null
  )
    return oldDate;

  _datetime = new Date(oldDate.year, oldDate.month - 1, oldDate.day);
  if (time && (time['hour'] || time['minute'])) {
    _datetime.setHours(time['hour'], time['minute']);
  }
  return _datetime;
}

export function getDateStr(oldDate: any, time?: any): any {
  var _datetime = getDateObj(oldDate, time);
  if (!_datetime) return '';

  var _datetimeStr = oldDate.year + '-' + oldDate.month + '-' + oldDate.day;
  return _datetimeStr;
}

export function getNgbTime(time: any): any {
  let date = new Date(time);
  if (date && time) {
    return {
      hour: date.getHours(),
      minute: date.getMinutes(),
    };
  } else {
    return;
  }
}

export function formValueDates(obj: any, adjust: boolean): any {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null)
      formValueDates(obj[key], true);

    if (String(key).includes('NGB')) {
      if (
        adjust &&
        obj[key] !== null &&
        obj[key] !== undefined
        // obj[key] !== {}
      ) {
        // delete obj[key];
        obj[key.replace('NGB', '')] = adjustTimeZone(getDateObj(obj[key]));
      } else if (
        obj[key] !== null &&
        obj[key] !== undefined
        // obj[key] !== {}
      ) {
        obj[key.replace('NGB', '')] = getDateObj(obj[key]);
      }
    }
  });
  return obj;
}
export function adjustTimeZone(_date: Date) {
  if (_date) _date.setHours(_date.getHours() - _date.getTimezoneOffset() / 60);
  return _date;
}

/**************************************************************
 * used to get ng bootstrap datepicker object  from date object
 * take date object in string format or date format
 * @param oldDate
 */
export function getNgbDate(oldDate: any): any {
  if (oldDate == null) return null;
  let dateObj = new Date(oldDate) || null;
  if (dateObj && oldDate) {
    //let dateStr = dateObj.toJSON();
    const year = dateObj.getFullYear(); //dateStr.substr(0, 4)
    const month = dateObj.getMonth() + 1; // dateStr.substr(5, 2)
    const day = dateObj.getDate(); //dateStr.substr(8, 2);
    if (isNumber(year) && isNumber(month) && isNumber(day)) {
      return {
        year: +year,
        month: +month,
        day: +day,
      };
    } else {
      return null;
    }
  }
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function getDayKey(dayNum) {
  let dayInWeek;
  switch (dayNum) {
    case 0:
      dayInWeek = 'Sunday';
      break;
    case 1:
      dayInWeek = 'Monday';
      break;
    case 2:
      dayInWeek = 'Tuesday';
      break;
    case 3:
      dayInWeek = 'Wednesday';
      break;
    case 4:
      dayInWeek = 'Thursday';
      break;
    case 5:
      dayInWeek = 'Friday';
      break;
    case 6:
      dayInWeek = 'Saturday';
      break;
  }
  return dayInWeek;
}

// export function getAccountData() {
//   var returnAccountSetupData: AccountSetup = new AccountSetup();
//   returnAccountSetupData = this._UIStateService._AppGlobals.AccountData;
//   // if (!((localStorage.getItem('AccountData') == 'null') || (localStorage.getItem('AccountData') == null))) {

//   //   returnAccountSetupData = JSON.parse(localStorage.getItem('AccountData')) ;
//   // } else {

//   // }
//   return returnAccountSetupData;
// }
//"^(01)[0-9]{9}$"
export const MASKS = {
  phoneSample: [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  egyMobileMask: [
    /[0]/,
    /[1]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  KSAMobileMask: [
    /[0]/,
    /[5]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};

export function isMultipleBranches(): boolean {
  if (localStorage.getItem('multiBranch') == 'true') return true;
  return false;
}
export function getBranches(): any[] {
  return JSON.parse(localStorage.getItem('branches'));
}

//////////////////// start ahmed Saeed Methods \\\\\\\\\\\\\\\\\\\\\
export function getDatesBetweenTwoDays(start?: Date, end?: Date) {
  var days = [
    { id: 2, name: 'Sunday', sysNo: 0 },
    { id: 3, name: 'Monday', sysNo: 1 },
    { id: 4, name: 'Tuesday', sysNo: 2 },
    { id: 5, name: 'Wednesday', sysNo: 3 },
    { id: 6, name: 'Thursday', sysNo: 4 },
    { id: 7, name: 'Friday', sysNo: 5 },
    { id: 1, name: 'Saturday', sysNo: 6 },
  ];

  var wkStart = start ? start : new Date();
  var wkEnd = end
    ? end
    : new Date(new Date(wkStart).setDate(wkStart.getDate() + 30));

  let daylist = getDaysArray(
    new Date(formatDate(wkStart.toUTCString())),
    new Date(formatDate(wkEnd.toUTCString()))
  );
  daylist
    .map((v) => v.toISOString().slice(0, 10))
    .join(',')
    .split(',');
  let datesRange = [];
  for (let day = 0; day < daylist.length; day++) {
    const _date = daylist[day];
    let d = new Date(_date);
    let dayObj = days.find((el) => d.getDay() === el.sysNo);
    let dayId = d.getDate(); //dayObj ? dayObj.id : null;
    let sysDay = dayObj.id; //dayObj ? dayObj.sysNo : null;
    let dayChar = dayObj.name[0];
    datesRange.push({
      dayNo: dayId,
      _date: formatDate(d.toUTCString()),
      sysNo: sysDay,
      char: dayChar,
    });
  }

  return datesRange;
}

export const getDaysArray = (start, end) => {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};
//////////////////// end ahmed Saeed Methods \\\\\\\\\\\\\\\\\\\\\


export class GuidGenerator {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}