import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { CompanyImagesServiceEditedName } from '../company/company-image-edited-name.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SharedService } from '../other/shared.service';
import { TransactionsToolsSearchCriteria } from '../../models/search-criterias/transactions-tools-search-criteria';
import { TransactionsToolsSearchResult } from '../../models/search-result/transactions-tools-search-result';
import { WeekDaysSearchResult } from '../../models/search-result/week-days-search-result';
import { GetCashRefundForEditModel } from 'src/app/models/classes/transactions/get-cash-refund-for-edit-model';
import { EditCashRefundModel } from 'src/app/models/classes/transactions/edit-cash-refund-model';
import { ExecutionResponse } from 'src/app/models/support/execution-response';

import { GetCurrentEmployeeItemsSearchResult } from 'src/app/models/search-result/get-current-employee-items-search-result';
import { CashRefundForEditSearchResult } from 'src/app/models/search-result/cash-refund-for-edit-search-result';
import { EditExpenseRefundModel } from 'src/app/models/classes/transactions/edit-expense-refund-model';
import { GetExpenseForEditModel } from 'src/app/models/classes/transactions/get-expense-for-edit-model';
import { ExpenseRefundForEditSearchResult } from 'src/app/models/search-result/expense-refund-for-edit-search-result';
import { ExpenseForEditSearchResult } from 'src/app/models/search-result/expense-for-edit-search-result';
import { GetExpenseRefundForEditModel } from 'src/app/models/classes/transactions/get-expense-refund-for-edit-model';
import { EditExpenseModel } from 'src/app/models/classes/transactions/edit-expense-model';
import { GetBillInfoModel } from 'src/app/models/classes/transactions/get-bill-Info-model';
import { EditBillItemEmployeeModel } from 'src/app/models/classes/transactions/edit-bill-Item-employee-model';
import { GetBillInfoSearchResult } from 'src/app/models/search-result/get-bill-info-search-result';
import { GetCurrentEmployeeItemsModel } from 'src/app/models/classes/transactions/get-cuurent-employee-items-model';
import { GetCurrentEmployeeItemInfoSearchResult } from 'src/app/models/search-result/get-current-employee-item-info-search-result';
import { GetCurrentEmployeeItemInfoModel } from 'src/app/models/classes/transactions/get-cuurent-employee-item-info-model';
import { BillRefundDTO } from 'src/app/models/classes/transactions/bill-refund-DTO';
import { GetBillRefundsModel } from 'src/app/models/classes/transactions/get-bill-refunds-model';

@Injectable({
  providedIn: 'root'
})
export class TransactionsSecondService extends BaseService {

  private transactionsSecondUrl;
  private transactionsUrl;

  constructor(private http: HttpClient, private _BaseService: BaseServiceSZ,
    private sharedService: SharedService,
    private companyImagesService: CompanyImagesServiceEditedName) {
    super();
    this.transactionsSecondUrl = this.sharedService.ApiUrl + 'TransactionsSecond';
    this.transactionsUrl = this.sharedService.ApiUrl + 'Transactions';
  }


  getTransactionsTools(transactionsToolsSearchCriteria: TransactionsToolsSearchCriteria): Observable<TransactionsToolsSearchResult> {

    return this.http.post<TransactionsToolsSearchResult>(this.transactionsSecondUrl + '/GetTransactionsTools', transactionsToolsSearchCriteria, super.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<TransactionsToolsSearchResult>('GetTransactionsTools[]'))
      );
  }


  getWeekDays(): Observable<WeekDaysSearchResult> {

    return this.http.post<WeekDaysSearchResult>(this.transactionsSecondUrl + '/GetWeekDays', null, super.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<WeekDaysSearchResult>('GetWeekDays[]'))
      );
  }
  GetCashRefundForEdit(model: GetCashRefundForEditModel): Observable<CashRefundForEditSearchResult> {
    return this.http.get<CashRefundForEditSearchResult>(this.transactionsSecondUrl
      + '/GetCashRefundForEdit', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(model) })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
  EditCashRefund(model: EditCashRefundModel): Observable<ExecutionResponse<boolean>> {

    return this.http.post<ExecutionResponse<boolean>>(this.transactionsSecondUrl + '/EditCashRefund', model, super.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('Edit Cash Refund'))
      );
  }
  GetExpenseRefundForEdit(model: GetExpenseRefundForEditModel): Observable<ExpenseRefundForEditSearchResult> {
    return this.http.get<ExpenseRefundForEditSearchResult>(this.transactionsSecondUrl
      + '/GetExpenseRefundForEdit', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(model) })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
  EditExpenseRefund(model: EditExpenseRefundModel): Observable<ExecutionResponse<boolean>> {

    return this.http.post<ExecutionResponse<boolean>>(this.transactionsSecondUrl + '/EditExpenseRefund', model, super.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('Edit Expense Refund'))
      );
  }
  GetExpenseForEdit(model: GetExpenseForEditModel): Observable<ExpenseForEditSearchResult> {
    return this.http.get<ExpenseForEditSearchResult>(this.transactionsSecondUrl
      + '/GetExpenseForEdit', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(model) })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
  EditExpense(model: EditExpenseModel): Observable<ExecutionResponse<boolean>> {
    return this.http.post<ExecutionResponse<boolean>>(this.transactionsSecondUrl + '/EditExpense', model, super.getHeaders())
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('Edit Expense'))
      );
  }
  GetBillInfo(model: GetBillInfoModel): Observable<GetBillInfoSearchResult> {
    return this.http.get<GetBillInfoSearchResult>(this.transactionsSecondUrl
      + '/GetBillInfo', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(model) })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
  EditBillItemEmployee(model: EditBillItemEmployeeModel): Observable<ExecutionResponse<boolean>> {
    return this.http.post<ExecutionResponse<boolean>>(this.transactionsSecondUrl + '/EditBillItemEmployee', model, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),
        catchError(this._BaseService.handleError<ExecutionResponse<boolean>>('Edit Bill Item Employee'))
      );
  }
  GetCurrentEmployeeItems(model: GetCurrentEmployeeItemsModel): Observable<GetCurrentEmployeeItemsSearchResult> {
    return this.http.get<GetCurrentEmployeeItemsSearchResult>(this.transactionsSecondUrl
      + '/GetCurrentEmployeeItems', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(model) })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
  GetCurrentEmployeeItemInfo(model: GetCurrentEmployeeItemInfoModel): Observable<GetCurrentEmployeeItemInfoSearchResult> {
    return this.http.get<GetCurrentEmployeeItemInfoSearchResult>(this.transactionsSecondUrl
      + '/GetCurrentEmployeeItemInfo', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(model) })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
  GetBillRefunds(model: GetBillRefundsModel): Observable<ExecutionResponse<BillRefundDTO[]>> {
    return this.http.get<ExecutionResponse<BillRefundDTO[]>>(this.transactionsUrl
      + '/GetBillRefunds', { headers: this.sharedService.getHeaders().headers, params: this.sharedService.ToHttpParams(model) })
      .pipe(
        tap(res => {

          this._BaseService.ValidationResult(res);
        })
      );
  }
}
