export enum ClientBalanceTransactionType {

  AddToClientBalance = 1,
  WithdrawBalanceToTheClient = 2,
  PayForPostpaidTransaction = 3,
  RemainingFromPostpaidTransaction = 4,
  PayForPostpaidTransactionFromCashSale = 5,
  UseClientBalanceInBill = 6,
  AddToClientBalanceFromBill = 7,
  AddToClientBalanceFromBillRefund = 8,
  AddToClientBalanceFromBillRefundPaymentMethods = 9,
  AddToClientBalanceFromBillWithPostpaidRefund = 10
}
