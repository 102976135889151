import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  AutoRegistrationService,
  BaseForm,
  ComponentBase,
  COUNTRY,
  SharedService,
} from 'core';
import { takeUntil } from 'rxjs/operators';
import { CONFIG } from '../../registration.module';
import { AutoRegistrationConfig } from '../../models/auto-registration-config';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SupportChatService } from 'projects/core/src/lib/services/support-chat.service';
import { environment } from 'src/environments/environment';
import {CookieService} from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends ComponentBase implements BaseForm, OnInit {
  hide: boolean = true;
  form!: FormGroup;
  countries: COUNTRY[] = [];
  @ViewChild('videoElement') videoElement: ElementRef;

  constructor(
    private supportChatService: SupportChatService,
    private readonly autoRegistrationSer: AutoRegistrationService,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    @Inject(CONFIG) public config: AutoRegistrationConfig,
    readonly translateService: TranslateService,
    private cookieService: CookieService , 
    readonly title: Title,
    private spinnerService:NgxSpinnerService
  ) {
    super();

    translateService.onLangChange.subscribe((_) => {
      title.setTitle(translateService.instant('Title_login'));
    });
    title.setTitle(translateService.instant('Title_login'));
  }
  
  ngOnInit(): void {
    console.log('login component init');
    this.reset();
    this.initForm();
    this.supportChatService.chatScript();
  }


  reset() {
    
    var lang = !localStorage.getItem('lang')
      ? 'ar'
      : localStorage.getItem('lang');
      var dir = !localStorage.getItem('dir')
        ? 'rtl'
        : localStorage.getItem('dir');

      localStorage.clear();

      localStorage.setItem('lang', lang);
      localStorage.setItem('dir', dir);
      this.getDeviceId();

    this.cookieService.delete('tokenObject', '/', `.${environment.RootDomain}`);
  }

  getDeviceId() {
    const client = new (window as any).ClientJS();
    var deviceId = client.getFingerprint();
    localStorage.setItem('DeviceId', deviceId);
  }


  initForm(): void {
    this.form = new UntypedFormGroup({
      UserName: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
      Password: new UntypedFormControl(null, [
        Validators.required,
        Validators.minLength(4),
      ]),
    });
  }

  onSubmit(): void {
    this.loading = true;
    this.spinnerService.show();
    this.autoRegistrationSer
      .login(this.form.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.checkResponse = this.sharedService.checkResposeStatus(
          res.Message,
          res.State
        );
        this.loading = false;
        this.spinnerService.hide();

        if (this.checkResponse) {
          this.sharedService.setLocalStorage('token', res.Result.Token!);
          this.isSzSupport = res.Result.IsSzSupport ?? false;
          this.router.navigate(['./branch-list'], {
            queryParams: { isSzSupport: res.Result.IsSzSupport },
          });

         
        } else {
          if (res.Result.RegisteredPhasesData) {
            this.sharedService.setLocalStorage(
              'RegistrationCycleLogId',
              res.Result.RegisteredPhasesData?.RegistrationCycleLogId!
            );
            if (!res.Result.RegisteredPhasesData?.IsMobileNumberVerified) {
              this.router.navigate(['../verify'], {
                queryParams: {
                  RegistrationCycleLogId:
                    res.Result.RegisteredPhasesData?.RegistrationCycleLogId,
                  user: this.form.get('UserName')?.value,
                  requestType: 'auto',
                  isAutoResend: true,
                },
              });
              return;
            }
            this.sharedService.goToPhase(
              res.Result?.RegisteredPhasesData?.LastPhaseNumber!
            );
          }
        }
      });
  }

  
}
