import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GbSocketService } from './socket/gb-socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { VersionCheckService } from './services/VersionCheckService ';

 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'], 
  
})
export class AppComponent implements OnInit {
  title = 'Glamera Business';

  constructor(
    private versionCheckService: VersionCheckService,
    private translate: TranslateService,
    private gbSocketService: GbSocketService,
    private spinner : NgxSpinnerService
  ) {

  }
  ngOnInit() {
    this.versionCheckService.initVersionCheck();
    console.log('app component init');
    if (!localStorage.getItem('lang')) { localStorage.setItem('lang', 'ar') }
    if (!localStorage.getItem('dir')) { localStorage.setItem('dir', 'rtl') }
    // this.gbSocketService.connect();
    this.getDeviceId();

    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 800);
  }

  getDeviceId() {
    const client = new (window as any).ClientJS();
    var deviceId = client.getFingerprint();
    localStorage.setItem('DeviceId', deviceId);
  }

}
