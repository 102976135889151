import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ResponseState } from '../../models/support/response-state';
import { SharedService } from '../../services/other/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from 'src/app/services/client/client.service';
import { GymClientMembershipFrozenDaysLog } from 'src/app/models/classes/gym/gym-client-membership-frozen-days-log';
import { WeekDays } from '../../models/enums/week-days';
import { AlertMethod } from '../../models/enums/alert-method';
import { AlertStatus } from '../../models/enums/alert-status';
import { GymActivityType } from '../../models/enums/gym-activity-type';
import { PeriodStatus } from 'src/app/models/enums/gym-membership-period-status';
import { CancelationWay } from 'src/app/models/enums/cancelation-way';
import { GymMembershipClientStatus } from 'src/app/models/enums/gym-membership-client-status';
import { GymClientMembershipFrozenDaysLogServiceService } from 'src/app/services/gym/gym-client-membership-frozen-days-log-service.service';
import { GymClientMembershipsSearchCriteria } from 'src/app/models/search-criterias/gym-client-memberships-searchcriteria';
import { BaseServiceSZ } from 'src/app/services/other/baseService';
import { ServiceService } from 'src/app/services/service/service.service';
import { GymClientMembershipService } from 'src/app/services/gym/gym-client-membership.service';
import { ServiceSearchCriteria } from 'src/app/models/search-criterias/service-search-criteria';
import { ServiceType } from 'src/app/models/enums/service-type';
import { ClientSearchCriteria } from 'src/app/models/search-criterias/client-search-criteria';
import { GetLiteForGBClientDTO } from 'src/app/models/classes/client/get-lite-for-gb-client';
import { BookingService } from 'src/app/dashboard/bookings/service/booking.service';
import { ClientsService } from 'src/app/dashboard/membership/clients/services/clients.service';
import { Service } from 'src/app/dashboard/bookings/models/Service';
import { ClientBasicDTO } from 'src/app/dashboard/membership/clients/models/client-basic-dto';
import { CreateGymClientMembershipFrozenDaysLog } from 'src/app/models/classes/gym/create-gym-client-membership-frozen-days-log';
import { GymClientMembershipForDropDown } from 'src/app/models/classes/gym/gym-client-membership-for-drop-down';

@Component({
  selector: 'app-modal-gym-membership-freez-add-edit',
  templateUrl: './modal-gym-membership-freez-add-edit.component.html',
  styleUrls: ['./modal-gym-membership-freez-add-edit.component.scss'],
  providers: [DatePipe],
})
export class ModalGymMembershipFreezAddEdit implements OnInit {

  @Input() GymClientMembershipFreezId: number;
  public LoadingGetGymClientMembershipFreez: boolean;
  public gymClientMembershipFrozenDaysLog: GymClientMembershipFrozenDaysLog;
  @Output() public DoneSave = new EventEmitter<boolean>();

  public AllowUseGym: boolean = false;
  public FreezClientId: number;
  public FreezGymClientMembershipId: number;

  public MultiDeleteList: number[] = [];

  public GetGymMemberShipsForMulti: boolean = false;

  public FrozenDaysCount: number;
  public MultiFrozenDaysCount: number;

  public FrozenDaysStartDate: Date;
  public FrozenDaysEndDate: Date;
  public ForMultiFrozenDays: boolean;

  public MultiFrozenDaysStartDate: Date;
  public MultiFrozenDaysEndDate: Date;

  public SendingData: boolean = false;
  public MultiSendingData: boolean = false;

  public MaxDate: string;
  public MinDate: string;
  public ClientsForSearch: GetLiteForGBClientDTO[];
  public ClientsForFreez: GetLiteForGBClientDTO[];
  public Services: Service[];
  public ClientIds: number[] = [];
  public ServiceIds: number[] = [];
  public ServiceId: number;
  public Memberships: any[];
  public freeText: string;

  public GymClientMemberships: GymClientMembershipForDropDown[] = [];
  public totalCount: number = 0;
  public totalCountForFreez: number = 0;
  public MultiEditState: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public clientService: ClientService,
    public sharedService: SharedService,
    private _baseService: BaseServiceSZ,
    private toastr: ToastrService,
    private clientsService: ClientsService,
    private bookingService: BookingService,
    private gymClientMembershipService: GymClientMembershipService,
    private serviceService: ServiceService,
    private gymClientMembershipFrozenDaysLogService: GymClientMembershipFrozenDaysLogServiceService,
    public toaster: ToastrService
  ) { }

  ngOnInit(): void {

    this.GetGymMembershipFreezInfo();

    this.getClients('', 3);
    this.getServices('');
    this.filterClients('');
    this.getMemberships('');
  }

  IsFormValid(): boolean {
    if (
      this.FreezGymClientMembershipId &&
      this.FrozenDaysCount &&
      this.FrozenDaysStartDate &&
      this.FrozenDaysEndDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  customSearchFn(term: string, item: ClientBasicDTO) {
    term = term.toLowerCase();
    return (
      item.Name.toLowerCase().indexOf(term) > -1 ||
      item.Mobile.toLowerCase().indexOf(term) > -1
    );
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }
  public get WeekDays(): typeof WeekDays {
    return WeekDays;
  }
  public get alertMethod(): typeof AlertMethod {
    return AlertMethod;
  }
  public get alertStatus(): typeof AlertStatus {
    return AlertStatus;
  }
  public get periodStatus(): typeof PeriodStatus {
    return PeriodStatus;
  }
  public get gymMembershipClientStatus(): typeof GymMembershipClientStatus {
    return GymMembershipClientStatus;
  }

  public get cancelationWay(): typeof CancelationWay {
    return CancelationWay;
  }
  getGymActivityTypeString(gymActivityType: GymActivityType): string {
    return 'GymActivityType' + GymActivityType[gymActivityType];
  }

  FrozenDaysCountChanged(event) {
    if (event.target.value) {
      var frozenDaysFrom = new Date(this.FrozenDaysStartDate);

      if (frozenDaysFrom) {
        var frozenDaysTo = this.AddDays(frozenDaysFrom, +event.target.value);

        this.FrozenDaysEndDate = frozenDaysTo;
      }
    }
  }
  FrozenDaysStartDateChanged(event) {
    var frozenDaysFrom: Date = event
      ? event?.target?.valueAsDate
      : this.FrozenDaysStartDate;

    if (frozenDaysFrom && this.FrozenDaysCount && this.FrozenDaysCount > 0) {
      var frozenDaysTo = this.AddDays(frozenDaysFrom, this.FrozenDaysCount);

      this.FrozenDaysEndDate = frozenDaysTo;
    }
  }
  FrozenDaysEndDateChanged(event) {

    this.HandleDates();
    var frozenDaysFrom = new Date(this.FrozenDaysStartDate);
    var frozenDaysTo = new Date(this.FrozenDaysEndDate);

    if (frozenDaysFrom && frozenDaysTo) {
      var diff = Math.abs(frozenDaysFrom.getTime() - frozenDaysTo.getTime());
      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      this.FrozenDaysCount = diffDays;
    }
  }
  AddDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  HandleDates() {

    this.FrozenDaysStartDate = (this.FrozenDaysStartDate + "").indexOf('T') > -1 &&
      (this.FrozenDaysStartDate + "").indexOf('GM') <= -1 ?
      new Date((this.FrozenDaysStartDate + "").split('T')[0]) : this.FrozenDaysStartDate;

    this.FrozenDaysEndDate = (this.FrozenDaysEndDate + "").indexOf('T') > -1 &&
      (this.FrozenDaysEndDate + "").indexOf('GM') <= -1 ?
      new Date((this.FrozenDaysEndDate + "").split('T')[0]) : this.FrozenDaysEndDate;

    this.MultiFrozenDaysStartDate = (this.MultiFrozenDaysStartDate + "").indexOf('T') > -1 &&
      (this.MultiFrozenDaysStartDate + "").indexOf('GM') <= -1 ?
      new Date((this.MultiFrozenDaysStartDate + "").split('T')[0]) : this.MultiFrozenDaysStartDate;

    this.MultiFrozenDaysEndDate = (this.MultiFrozenDaysEndDate + "").indexOf('T') > -1 &&
      (this.MultiFrozenDaysEndDate + "").indexOf('GM') <= -1 ?
      new Date((this.MultiFrozenDaysEndDate + "").split('T')[0]) : this.MultiFrozenDaysEndDate;
  }

  Freez() {

    if (this.FreezGymClientMembershipId && this.FrozenDaysCount) {
      this.SendingData = true;

      var createGymClientMembershipFrozenDaysLog =
        new CreateGymClientMembershipFrozenDaysLog();
      createGymClientMembershipFrozenDaysLog.BranchId =
        this.sharedService.AccountSetupId;
      createGymClientMembershipFrozenDaysLog.GymClientMembershipId =
        this.FreezGymClientMembershipId;
      createGymClientMembershipFrozenDaysLog.FrozenDaysCount =
        this.FrozenDaysCount;
      createGymClientMembershipFrozenDaysLog.Id = this.GymClientMembershipFreezId ?? 0;

      this.HandleDates();

      createGymClientMembershipFrozenDaysLog.FrozenDaysStartDate =
        (this.FrozenDaysStartDate + "").indexOf('T') <= -1 &&
          (this.FrozenDaysStartDate + "").indexOf('GM') <= -1 ?
          new Date(this.FrozenDaysStartDate)
            .toISOString()
            .split('T')[0] :
          this.FrozenDaysStartDate.toISOString()
            .split('T')[0];

      createGymClientMembershipFrozenDaysLog.FrozenDaysEndDate =
        (this.FrozenDaysEndDate + "").indexOf('T') <= -1 &&
          (this.FrozenDaysEndDate + "").indexOf('GM') <= -1 ?
          new Date(this.FrozenDaysEndDate)
            .toISOString()
            .split('T')[0] :
          this.FrozenDaysEndDate.toISOString()
            .split('T')[0];

      if (this.GymClientMembershipFreezId > 0) {
        this.gymClientMembershipFrozenDaysLogService
          .UpdateGymClientMembershipFrozenDaysLog(
            createGymClientMembershipFrozenDaysLog
          )
          .subscribe((result) => {
            if (result.State == ResponseState.Success) {
              this.FreezGymClientMembershipId = null;
              this.FrozenDaysCount = null;
              this.FrozenDaysStartDate = null;
              this.FrozenDaysEndDate = null;
              this.FreezClientId = null;

              this.sharedService.ToastrSuccess(result.Message);
              this.sharedService.HideModal();
              this.DoneSave.emit(true);
              this.activeModal.close('Close click');
            }

            this.SendingData = false;
          });
      } else {
        this.gymClientMembershipFrozenDaysLogService
          .CreateGymClientMembershipFrozenDaysLog(
            createGymClientMembershipFrozenDaysLog
          )
          .subscribe((result) => {
            if (result.State == ResponseState.Success) {
              this.FreezGymClientMembershipId = null;
              this.FrozenDaysCount = null;
              this.FrozenDaysStartDate = null;
              this.FrozenDaysEndDate = null;
              this.FreezClientId = null;

              this.sharedService.ToastrSuccess(result.Message);
              this.sharedService.HideModal();
              this.DoneSave.emit(true);
              this.activeModal.close('Close click');
            }

            this.SendingData = false;
          });
      }
    }
  }

  FreezClient() {
    this.FreezGymClientMembershipId = null;
    this.ClientMembershipUseFrozenDaysChanged();
  }

  getClients(freeText, type: number) {

    var clientSearchCriteria: ClientSearchCriteria = new ClientSearchCriteria();
    clientSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    clientSearchCriteria.PagingEnabled = true;
    clientSearchCriteria.PageSize = 2;
    clientSearchCriteria.PageNumber = 0;
    clientSearchCriteria.FreeText = freeText || this.freeText;
    clientSearchCriteria.Status = 1;
    clientSearchCriteria.HaveActiveGymMembershipAndAvailableForFreeze = true;
    clientSearchCriteria.Id = this.FreezClientId;

    this.clientService
      .GetClientsLite(clientSearchCriteria)
      .subscribe((response) => {
        if (response.State == ResponseState.Success) {
          if (type == 1 || type == 3) this.ClientsForSearch = response.Clients;
          if (type == 2 || type == 3) this.ClientsForFreez = response.Clients;
        }
      });
  }

  getServices(freeText) {

    var serviceSearchCriteria = new ServiceSearchCriteria();
    serviceSearchCriteria.AccountSetupId = this.sharedService.AccountSetupId;
    serviceSearchCriteria.FreeText = freeText;
    serviceSearchCriteria.ServiceTypes.push(ServiceType.GymMembership);
    serviceSearchCriteria.GetOnlyThatUseFrozenDays = true;

    this.serviceService
      .searchServices(serviceSearchCriteria)
      .subscribe((res) => {
        this.Services = res.Services;
      });
  }

  filterdClients;
  filterClients(freeText) {
    this.clientsService
      .GetClients({
        FreeText: freeText,
        HaveActiveGymMembership: true,
      })
      .subscribe((res) => {
        this.filterdClients = res.Result;
      });
  }

  getMemberships(freeText) {
    this.bookingService
      .GetService({
        AccountSetupId: this.sharedService.AccountSetupId,
        CompanyId: this.sharedService.CompanyId,
        PageSize: 10,
        PagingEnabled: true,
        IsForTableView: true,
        ServiceTypes: [2],
        PageNumber: 0,
        FreeText: freeText,
      })
      .subscribe((res) => {
        this.Memberships = (<any>res).Services;
      });
  }

  ClientMembershipUseFrozenDaysChanged() {

    this.FrozenDaysCount = null;
    this.FrozenDaysStartDate = null;
    this.FrozenDaysEndDate = null;

    var gymClientMembership = this.GymClientMemberships?.find(
      (g) => g.Id == this.FreezGymClientMembershipId
    );
    if (this.FreezGymClientMembershipId && gymClientMembership) {
      this.MinDate = (gymClientMembership.GymMembershipStartDate + '').slice(
        0,
        10
      );
      this.MaxDate = (gymClientMembership.GymMembershipEndDate + '').slice(
        0,
        10
      );
    } else {
      this.MinDate = null;
      this.MaxDate = null;
    }
  }

  getClientMemberships(freeText) {
    this.GymClientMemberships = null;
    //this.HandlePaging();
    if (
      (this.FreezClientId && this.FreezClientId > 0) ||
      this.ForMultiFrozenDays
    ) {
      var clientGymMembershipsSearchCriteria =
        new GymClientMembershipsSearchCriteria();
      clientGymMembershipsSearchCriteria.AccountSetupId =
        this.sharedService.AccountSetupId;

      clientGymMembershipsSearchCriteria.ClientIds = [];

      if (this.FreezClientId && this.FreezClientId > 0) {
        clientGymMembershipsSearchCriteria.ClientIds.push(this.FreezClientId);
      }

      clientGymMembershipsSearchCriteria.FreeText = freeText;
      clientGymMembershipsSearchCriteria.GetUseFrozenDaysMemberships = true;

      clientGymMembershipsSearchCriteria.PagingEnabled = true;
      clientGymMembershipsSearchCriteria.PageNumber = 0;
      clientGymMembershipsSearchCriteria.PageSize = 10;

      clientGymMembershipsSearchCriteria.ForMultiFrozenDays =
        this.ForMultiFrozenDays;

      if (this.MultiFrozenDaysStartDate) {
        clientGymMembershipsSearchCriteria.MultiFrozenDaysStartDate = new Date(
          this.MultiFrozenDaysStartDate
        )
          .toISOString()
          .split('T')[0];
      }

      if (this.MultiFrozenDaysEndDate) {
        clientGymMembershipsSearchCriteria.MultiFrozenDaysEndDate = new Date(
          this.MultiFrozenDaysEndDate
        )
          .toISOString()
          .split('T')[0];
      }

      clientGymMembershipsSearchCriteria.MultiFrozenDaysCount =
        this.MultiFrozenDaysCount;

      this.gymClientMembershipService
        .GetGymClientMembershipsForDropDown(clientGymMembershipsSearchCriteria)
        .subscribe((result) => {
          if (result.State == ResponseState.Success) {
            if (
              !result.GymClientMemberships ||
              result.GymClientMemberships.length <= 0
            ) {
              this._baseService
                .getTranslation(
                  'Sorry!CanNotFoundGymClientMembershipThatUseFrozenDays!'
                )
                .subscribe((c) => {
                  this.toastr.warning(c);
                });
            }
            this.GymClientMemberships = result.GymClientMemberships;
            this.totalCountForFreez = result.TotalCount;
            this.ForMultiFrozenDays = false;
            this.GetGymMemberShipsForMulti = false;
          }

          this.ForMultiFrozenDays = false;
          this.GetGymMemberShipsForMulti = false;
        });
    }
  }

  divName;
  printPage() {
    this.divName = 'printClientMembershipFrozenDaysLogDetails';
    console.log(this.divName);

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  }

  GetGymMembershipFreezInfo() {

    if (this.GymClientMembershipFreezId > 0) {

      this.LoadingGetGymClientMembershipFreez = true;

      /*  var clientGymMembershipsSearchCriteria =
         new GymClientMembershipFrozenDaysLogsSearchCriteria();
       clientGymMembershipsSearchCriteria.AccountSetupId =
         this.sharedService.AccountSetupId;
       clientGymMembershipsSearchCriteria.Id = this.GymClientMembershipFreezId; */

      this.gymClientMembershipFrozenDaysLogService
        .GetGymClientMembershipFrozenDayLogInfo(this.GymClientMembershipFreezId)
        .subscribe((response) => {
          if (response.State == ResponseState.Success) {
            this.gymClientMembershipFrozenDaysLog = response.Result;

            this.FreezClientId =
              this.gymClientMembershipFrozenDaysLog.ClientId;

            this.getClients('', 3)

            this.getClientMemberships(null);

            this.FreezGymClientMembershipId =
              this.gymClientMembershipFrozenDaysLog.GymClientMembershipId;
            this.FrozenDaysCount = this.gymClientMembershipFrozenDaysLog.FrozenDaysCount;
            this.FrozenDaysStartDate =
              this.gymClientMembershipFrozenDaysLog.FrozenDaysStartDate;
            this.FrozenDaysEndDate = this.gymClientMembershipFrozenDaysLog.FrozenDaysEndDate;

          }
          this.LoadingGetGymClientMembershipFreez = false;
        });
    }
  }
}
