import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ClientBalanceStatus } from '../../models/enums/client-balance-status';
import { ClientBalanceTransactionDirection } from '../../models/enums/client-balance-transaction-direction';
import { ClientBalanceTransactionType } from '../../models/enums/client-balance-transaction-type';
import { ResponseState } from '../../models/support/response-state';
import { ClientBalanceDetailService } from '../../services/balances/client-balance-detail.service';
import { SharedService } from '../../services/other/shared.service';
import { TransactionsSecondService } from '../../services/transaction/transactions-second.service';
import { PaginationComponent } from '../pagination/pagination.component';
import { GetBillRefundsModel } from 'src/app/models/classes/transactions/get-bill-refunds-model';
import { ExecutionResponse } from 'src/app/models/support/execution-response';
import { BillRefundDTO } from 'src/app/models/classes/transactions/bill-refund-DTO';
import { CashSaleForGrid } from 'src/app/models/classes/transactions/cash-sale-for-grid';

@Component({
  selector: 'app-modal-bill-refunds-details',
  templateUrl: './modal-bill-refunds-details.component.html',
  styleUrls: ['./modal-bill-refunds-details.component.scss'],
  providers: [DatePipe]

})
export class ModalBillRefundsDetailsComponent implements OnInit {

  @ViewChild('pager', { static: true }) pager: PaginationComponent;

  @Input() Bill: CashSaleForGrid;
  public BillRefunds: BillRefundDTO[] = [];
  public Loading: boolean = false;
  public freeText: string;

  constructor(public activeModal: NgbActiveModal,
    public translate: TranslateService,
    public sharedService: SharedService,
    public _transactionsSecondService: TransactionsSecondService,
    private fb: UntypedFormBuilder,
    public datepipe: DatePipe,
    public clientBalanceDetailService: ClientBalanceDetailService) { }

  ngOnInit(): void {
   /*  this.pager.pageSize = 10;
    this.pager.ddlPageSize = 10;
    this.pager.GlobalPageIndex = 0; */
    this.GetClientBalanceDetails();
  }


  divName
  printPage() {

    this.divName = 'printBillRefunds';
    console.log(this.divName)

    let printContents = document.getElementById(this.divName).innerHTML;
    let originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
    // this.divName = null;
  }

  public get clientBalanceStatus(): typeof ClientBalanceStatus {
    return ClientBalanceStatus;
  }

  public get clientBalanceTransactionType(): typeof ClientBalanceTransactionType {
    return ClientBalanceTransactionType;
  }

  public get clientBalanceTransactionDirection(): typeof ClientBalanceTransactionDirection {
    return ClientBalanceTransactionDirection;
  }


  GetClientBalanceDetails() {
    this.Loading = true;
    var criteria = new GetBillRefundsModel();

    criteria.BillId = this.Bill.Id;

    this._transactionsSecondService
      .GetBillRefunds(criteria)
      .subscribe((response: ExecutionResponse<BillRefundDTO[]>) => {
        this.Loading = false;
        if (response.State == ResponseState.Success) {
          this.BillRefunds = response.Result;
        }
      });
  }

  ReciveFreeText(e) {
    console.log(e)
    this.freeText = e
    this.GetClientBalanceDetails();
  }
}
