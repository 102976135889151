
// Please change "willPublishOn" variable according to what environment you will publish on it




export const environment = {
  production: true,
    RootDomain: "glamera.com",
    ApiUrl: "https://api-dev.glamera.com/api/",
    NewApiUrl: "https://api-dev-new.glamera.com/api/",
    ThemeBuilderApiUrl: "https://api-builder-dev.glamera.com/",
    // ThemeBuilderApiUrl: "http://localhost:3000/",
    ApiUrlV2: "https://api-dev.glamera.com/api/v2/",
    UploadUrl: "https://api-dev.glamera.com/api/Upload/",
    OldApiUrlFromGlamatekWeb: "https:/api-dev.glamera.com/",

    ReportDevUrl: "http://localhost:60461/",
    SurePayUrl: 'https://localhost/Api/Merchant/v1/',
    OnlineReportDevUrl: "https://reports.glamera.com/",
    BillingPortal: "https://subscription-dev.glamera.com/",
    SubscriptionAPI: "https://api-dev-new.glamera.com/api/",
    BioTimeSignalRUrl: '',
  version:"0.0.0"
};
//  NewApiUrl: "https://api-new.glamera.com/api/",
// export const environment = willPublishOn == (PublishOn.Online + "") ?
//   {
//     production: true,
//     ApiUrl: "https://localhost:44340/api/",
//     ApiUrlV2: "https://localhost:44340/api/v2/",
//     UploadUrl: "https://api.glamera.com/api/Upload/",
//     OldApiUrlFromGlamatekWeb: "https:/api.glamera.com/",
//     ReportDevUrl: "https://reports.glamera.com/",
//     OnlineReportDevUrl: "https://reports.glamera.com/",
//   } : willPublishOn == (PublishOn.Stage + "") ?

//     {
//       production: true,
//       ApiUrl: "https://api-stage.glamera.com/api/",
//       ApiUrlV2: "https://api-stage.glamera.com/api/v2/",
//       UploadUrl: "https://api-stage.glamera.com/api/Upload/",
//       OldApiUrlFromGlamatekWeb: "https://api.glamour-plan.com/",
//       ReportDevUrl: "https://reports.glamera.com/",
//       OnlineReportDevUrl: "https://reports.glamera.com/",
//     } : willPublishOn == (PublishOn.Test + "") ?

//       {
//         production: true,
//         ApiUrl: "https://localhost:44340/api/",
//         ApiUrlV2: "https://localhost:44340/api/v2/",
//         UploadUrl: "https://localhost:44340/api/Upload/",
//         OldApiUrlFromGlamatekWeb: "https://localhost:44340/",
//         ReportDevUrl: "https://reports-dev.glamera.com/",
//         OnlineReportDevUrl: "https://reports-dev.glamera.com/",
//       } : null;
