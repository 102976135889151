<div id="printClientProfile">
  <div class="modal-header">
    <h5 class="modal-title text-capitalize" id="exampleModalLabel">
      <i class="fas fa-file"></i> &nbsp; {{'ClientProfile' | translate}} &nbsp; ( {{ClientName}} )
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">

      <div class="col-lg-12">
        <div class="text-start py-4">

          <div class="row" style="margin-top: -3% !important;">
            <div class="col-lg-12">
              <div class="btnsTab">
                <button type="button" class="btn" [class.selectedTab]="selectedTab === 1" (click)="tabClick(1)">
                  {{ 'ClientInfo' | translate }}
                </button>
                <button type="button" class="btn" [class.selectedTab]="selectedTab === 2" (click)="tabClick(2)">
                  {{ 'ClientBookings' | translate }}
                </button>
                <button type="button" class="btn" [class.selectedTab]="selectedTab === 3" (click)="tabClick(3)">
                  {{ 'ClientSales' | translate }}
                </button>
                <button type="button" class="btn" [class.selectedTab]="selectedTab === 4" (click)="tabClick(4)">
                  {{ 'ClientGroups' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="cardTabs">
            <div [hidden]="selectedTab != 1" class="tabsContent">
              <div class="row">
                <div class="col-lg-12" *ngIf="ClientInfo">
                  <div class="formCard">
                    <table class="table table-sm">
                      <tr *ngIf="ClientInfo.Code">
                        <td>{{ 'ClientCode' | translate }}</td>
                        <td>{{ ClientInfo.Code }}</td>

                      </tr>

                      <tr>
                        <td>{{ 'ClientName' | translate }}</td>
                        <td>{{ ClientInfo.Name }}</td>

                      </tr>

                      <tr>
                        <td>{{ 'ClientMobile' | translate }}</td>
                        <td>{{ ClientInfo.Mobile }}</td>

                      </tr>

                      <tr *ngIf="ClientInfo.Gender != gender.NotDefined">
                        <td>{{ 'TheGender' | translate }}</td>
                        <td>
                          <span *ngIf="ClientInfo.Gender == gender.Male"> {{ 'Male'| translate }} </span>
                          <span *ngIf="ClientInfo.Gender == gender.Female"> {{ 'Female'| translate }} </span>
                        </td>
                      </tr>

                      <tr *ngIf="ClientInfo.CountryName">
                        <td>{{ 'TheCountryName' | translate }}</td>
                        <td>{{ ClientInfo.CountryName }}</td>
                      </tr>

                      <tr *ngIf="ClientInfo.NationalityName">
                        <td>{{ 'NationalityName' | translate }}</td>
                        <td>{{ ClientInfo.NationalityName }}</td>
                      </tr>

                      <tr *ngIf="ClientInfo.Email">
                        <td>{{ 'TheEmail' | translate }}</td>
                        <td>{{ ClientInfo.Email }}</td>
                      </tr>

                      <tr *ngIf="ClientInfo.Address">
                        <td>{{ 'TheAddress' | translate }}</td>
                        <td>{{ ClientInfo.Address }}</td>
                      </tr>

                      <tr *ngIf="ClientInfo.BirthDate">
                        <td>{{ 'BirthDate' | translate }}</td>
                        <td>{{ ClientInfo.BirthDate | date: 'yyyy-MM-dd' }}</td>
                      </tr>
                      <tr>
                        <td>{{ 'CashClient' | translate }}</td>
                        <td>
                          <span *ngIf="ClientInfo.CashClient"> {{ 'Yes'| translate }} </span>
                          <span *ngIf="!ClientInfo.CashClient"> {{ 'No'| translate }} </span>
                        </td>

                      </tr>

                      <tr *ngIf="ClientInfo.FingerprintCode">
                        <td>{{ 'FingerprintCode' | translate }}</td>
                        <td>{{ ClientInfo.FingerprintCode }}</td>
                      </tr>

                      <tr>
                        <td>{{ 'IsBannedClient' | translate }}</td>
                        <td>
                          <span *ngIf="ClientInfo.IsBanned"> {{ 'Yes'| translate }} </span>
                          <span *ngIf="!ClientInfo.IsBanned"> {{ 'No'| translate }} </span>
                        </td>

                      </tr>

                      <tr *ngIf="ClientInfo.IsBanned && ClientInfo.BanReason">
                        <td>{{ 'BanReason' | translate }}</td>
                        <td>{{ ClientInfo.BanReason }}</td>
                      </tr>

                      <tr *ngIf="sharedService?.CurrentCompany?.AllowUseLoyaltyPointsSystem">
                        <td>{{ 'LoyaltyPointsBalance' | translate }}</td>

                        <td
                          [ngClass]="{'text-info': ClientInfo.TotalClientLoyaltyPoints <= 0, 'text-success' : ClientInfo.TotalClientLoyaltyPoints > 0 }"
                          style="font-size: 15;font-weight: bolder;"
                          *ngIf="sharedService?.CurrentCompany?.AllowUseLoyaltyPointsSystem">
                          {{ ClientInfo.TotalClientLoyaltyPoints + " " + ('Point' |translate) + " - " +
                          ClientInfo.TotalClientLoyaltyPointsAmount.toFixed(sharedService.GetFractionRoundCount) + "
                          " +
                          (sharedService.Lang== 'ar' ? sharedService.CurrencyNameAr :
                          sharedService.CurrencyNameEn) }}
                        </td>
                      </tr>

                      <tr>
                        <td>{{ 'TotalBookingsCount' | translate }}</td>
                        <td>{{ ClientInfo.BookingsCount }}</td>
                      </tr>

                      <tr>
                        <td>{{ 'TotalSalesCount' | translate }}</td>
                        <td>{{ ClientInfo.SalesCount }}</td>
                      </tr>

                    </table>


                  </div>
                </div>
                <div class="col-lg-12" *ngIf="LoadingGetClientInfo">
                  <div class="col-md-12 text-center py-4 my-4">
                    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                    <h1>{{'Loading' | translate}} </h1>
                  </div>
                </div>
              </div>
            </div>
            <div [hidden]="selectedTab != 2" class="tabsContent">
              <div class="row">

                <div class="col-lg-12" style="margin-top: 1%;">
                </div>

                <div class="col-lg-12" *ngIf="bookingsTotalCount" style="margin-top: 1%;">
                  <div class="formCard">
                    <table class="table table-sm">
                      <tr>
                        <td class="infoClass formatHeader">
                          {{ 'BookingsCount' | translate }}: &nbsp;&nbsp;{{
                          bookingsTotalCount }} </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="filter-card">
                    <div class="row px-2">
                      <div class="col-md-6 col-sm-8 col-12 px-2">
                        <label>{{ 'SearchByBranchNameOrItemsNames' | translate }}</label>
                        <app-search (SendFreeText)="BookingReciveFreeText($event)"
                          [SearchName]="('SearchByBranchNameOrItemsNames')">
                        </app-search>
                      </div>


                      <div class="col-md-3 col-sm-4  px-2">
                        <label>{{ 'BookingDateFrom' | translate }}</label>
                        <div class="input-group">
                          <input class="form-control" type="date" placeholder="{{ 'BookingDateFrom' | translate }}"
                            (change)="GetClientBookingsWithReset()" [(ngModel)]="BookingDateFrom">
                        </div>

                      </div>

                      <div class="col-md-3 col-sm-4  px-2">
                        <label>{{ 'BookingDateTo' | translate }}</label>
                        <div class="input-group">
                          <input class="form-control" type="date" placeholder="{{ 'BookingDateTo' | translate }}"
                            (change)="GetClientBookingsWithReset()" [(ngModel)]="BookingDateTo">
                        </div>

                      </div>



                    </div>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="(!LoadingGetBookings && ClientBookings); let ClientBookings;">
                  <div class="table-card">
                    <table class="table table-stripedd table-sm table-bordered">
                      <thead class="table-head">
                        <tr style="color: black; font-size: large;">

                          <th style="width: 10%;" class="formatTd text-center">{{ 'TheBranchName' | translate }}</th>
                          <th style="width: 18%;" class="formatTd text-center">{{ 'BookingDate' | translate }}</th>
                          <th style="width: 10%;" class="formatTd text-center">{{ 'ItemsCount' | translate }}</th>
                          <th style="width: 15%;" class="formatTd text-center">{{ 'ItemsNames' | translate }}</th>
                          <th style="width: 10%;" class="formatTd text-center">{{ 'BookingStatus' | translate }}</th>
                          <th style="width: 15%;" class="formatTd text-center">{{ 'PaidStatus' | translate }}</th>
                          <th style="width: 10%;" class="formatTd text-center">{{ 'OnAllAmount' | translate }}</th>
                          <th style="width: 12%;" class="formatTd text-center">{{ 'ThePaidAmount' | translate }}
                          </th>

                        </tr>
                      </thead>
                      <tbody>

                        <tr *ngFor="let clientBooking of ClientBookings; let i = index">

                          <td class="fontClass text-center">
                            <span class="overme20" title="{{ clientBooking.BranchName}}">
                              {{ clientBooking.BranchName }}
                            </span>
                          </td>

                          <td class="formatTd">{{ clientBooking.Date | date: 'yyyy-MM-dd # hh:mm a' }}</td>

                          <td class="formatTd">{{ clientBooking.ItemsCount }}</td>

                          <td class="fontClass text-center">
                            <span class="overme20" title="{{ clientBooking.ItemsNames}}">
                              {{ clientBooking.ItemsNames }}
                            </span>
                          </td>

                          <td class="formatTd">
                            <span
                              *ngIf="clientBooking.Status == bookingStatus.Unconfirmed">{{'Unconfirmed'|translate}}</span>
                            <span
                              *ngIf="clientBooking.Status == bookingStatus.Confirmed">{{'Confirmed'|translate}}</span>
                            <span *ngIf="clientBooking.Status == bookingStatus.Arrived">{{'Arrived'|translate}}</span>
                            <span
                              *ngIf="clientBooking.Status == bookingStatus.Completed">{{'Completed'|translate}}</span>
                            <!-- <span
                              *ngIf="clientBooking.Status == bookingStatus.SurveyCompleted">{{'SurveyCompleted'|translate}}</span>
                            <span *ngIf="clientBooking.Status == bookingStatus.DidNotShow">{{"noshow"|translate}}</span> -->
                            <span
                              *ngIf="clientBooking.Status == bookingStatus.Canceled">{{'Cancelled'|translate}}</span>
                          </td>

                          <td class="formatTd">
                            <span *ngIf="clientBooking.PaidStatus == paidStatus.Paid" class="text-success">{{
                              'DonePayment' |
                              translate
                              }} </span>
                            <span *ngIf="clientBooking.PaidStatus == paidStatus.NotPaid" class="text-danger">{{
                              'NotDonePayment' |
                              translate }}</span>
                            <span *ngIf="clientBooking.PaidStatus == paidStatus.PartialPaid" class="text-warning">{{
                              'PartialPayment' |
                              translate }}</span>
                          </td>

                          <td class="formatTd">{{ clientBooking.Amount.toFixed(sharedService.GetFractionRoundCount) + "
                            " + CurrencyName}}</td>
                          <td class="formatTd">{{ clientBooking.PaidAmount.toFixed(sharedService.GetFractionRoundCount)
                            + " " + CurrencyName}}</td>


                        </tr>


                      </tbody>
                    </table>
                    <div class="text-center py-4 my-4" *ngIf="ClientBookings.length == 0">
                      <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" [hidden]="!ClientBookings">
                  <app-pagination (GetSources)="GetClientBookings()" #bookingsPager></app-pagination>
                </div>

                <div class="col-lg-12" *ngIf="LoadingGetBookings">
                  <div class="col-md-12 text-center py-4 my-4">
                    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                    <h1>{{'Loading' | translate}} </h1>
                  </div>
                </div>

              </div>
            </div>
            <div [hidden]="selectedTab != 3" class="tabsContent">
              <div class="row">

                <div class="col-lg-12" style="margin-top: 1%;">
                </div>

                <div class="col-lg-12" *ngIf="salesTotalCount" style="margin-top: 1%;">
                  <div class="formCard">
                    <table class="table table-sm">
                      <tr>
                        <td class="infoClass formatHeader">{{ 'TotalSalesCount' | translate }}: &nbsp;&nbsp;{{
                          salesTotalCount }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="filter-card">
                    <div class="row px-2">
                      <div class="col-md-6 col-sm-8 col-12 px-2">
                        <label>{{ 'SearchByBranchNameOrItemsNames' | translate }}</label>
                        <app-search (SendFreeText)="SalesReciveFreeText($event)"
                          [SearchName]="('SearchByBranchNameOrItemsNames')">
                        </app-search>
                      </div>

                      <div class="col-md-3 col-sm-4  px-2">
                        <label>{{ 'SaleDateFrom' | translate }}</label>
                        <div class="input-group">
                          <input class="form-control" type="date" placeholder="{{ 'SaleDateFrom' | translate }}"
                            (change)="GetClientSalesWithReset()" [(ngModel)]="SaleDateFrom">
                        </div>

                      </div>

                      <div class="col-md-3 col-sm-4  px-2">
                        <label>{{ 'SaleDateTo' | translate }}</label>
                        <div class="input-group">
                          <input class="form-control" type="date" placeholder="{{ 'SaleDateTo' | translate }}"
                            (change)="GetClientSalesWithReset()" [(ngModel)]="SaleDateTo">
                        </div>

                      </div>



                    </div>
                  </div>
                </div>

                <div class="col-md-12" *ngIf="(!LoadingGetSales && ClientSales); let ClientSales;">
                  <div class="table-card">
                    <table class="table table-stripedd table-sm table-bordered">
                      <thead class="table-head">
                        <tr style="color: black; font-size: large;">

                          <th style="width: 10%;" class="formatTd text-center">{{ 'TheBranchName' | translate }}</th>
                          <th style="width: 15%;" class="formatTd text-center">{{ 'SaleCode' | translate }}</th>
                          <th style="width: 20%;" class="formatTd text-center">{{ 'SaleDate' | translate }}</th>
                          <th style="width: 10%;" class="formatTd text-center">{{ 'ItemsCount' | translate }}</th>
                          <th style="width: 20%;" class="formatTd text-center">{{ 'ItemsNames' | translate }}</th>
                          <th style="width: 10%;" class="formatTd text-center">{{ 'OnAllAmount' | translate }}</th>

                        </tr>
                      </thead>
                      <tbody>

                        <tr *ngFor="let clientSale of ClientSales; let i = index">

                          <td class="fontClass text-center">
                            <span class="overme30" title="{{ clientSale.BranchName}}">
                              {{ clientSale.BranchName }}
                            </span>
                          </td>

                          <td class="formatTd">{{ clientSale.TransactionCodeText }}</td>

                          <td class="formatTd">{{ clientSale.Date | date: 'yyyy-MM-dd # hh:mm a' }}</td>

                          <td class="formatTd">{{ clientSale.ItemsCount }}</td>

                          <td class="fontClass text-center">
                            <span class="overme30" title="{{ clientSale.ItemsNames}}">
                              {{ clientSale.ItemsNames }}
                            </span>
                          </td>

                          <td class="formatTd">{{ clientSale.Amount.toFixed(sharedService.GetFractionRoundCount) + " " +
                            CurrencyName}}</td>

                        </tr>

                      </tbody>
                    </table>
                    <div class="text-center py-4 my-4" *ngIf="ClientSales.length == 0">
                      <h4 class="py-4">{{'NoDataFound' | translate}}</h4>
                    </div>
                  </div>
                </div>

                <div class="col-md-12" [hidden]="!ClientSales">
                  <app-pagination (GetSources)="GetClientSales()" #salesPager></app-pagination>
                </div>

                <div class="col-lg-12" *ngIf="LoadingGetSales">
                  <div class="col-md-12 text-center py-4 my-4">
                    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                    <h1>{{'Loading' | translate}} </h1>
                  </div>
                </div>

              </div>
            </div>

            <div [hidden]="selectedTab != 4" class="tabsContent">
              <div class="row">

                <div class="col-lg-12" style="margin-top: 1%;">
                </div>

                <div class="col-lg-12" *ngIf="ClientGroupsIds && Groups" style="margin-top: 1%;">
                  <div class="formCard">
                    <table class="table table-sm">
                      <tr>
                        <td class="infoClass formatHeader">{{ 'ClientGroupsCount' | translate }} : &nbsp;&nbsp;{{
                          ClientGroupsCount }}</td>
                      </tr>

                      <tr>
                        <td class="text-info"> <i class="fas fa-info-circle"></i> &nbsp; {{
                          'YouCanDeleteOrAddInClientGroups' | translate }}</td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="form-group col-lg-12">

                  <label>{{ 'ClientGroups' | translate }}</label>
                  <ng-select placeholder="{{ 'ClientGroups' | translate }}" (search)="onSearchGroups($event)"
                    [multiple]="true" [hideSelected]="true" [items]="Groups" bindLabel="Name" bindValue="Id"
                    [(ngModel)]="ClientGroupsIds" [ngModelOptions]="{standalone: true}"
                    [searchFn]="customSearchFnGroups">
                  </ng-select>

                </div>

                <div class="col-lg-12" *ngIf="LoadingGetClientGroups">
                  <div class="col-md-12 text-center py-4 my-4">
                    <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                    <h1>{{'Loading' | translate}} </h1>
                  </div>
                </div>

              </div>
            </div>
          </div>



        </div>
      </div>

    </div>

  </div>
  <div class="modal-footer">

    <button type="button" *ngIf="selectedTab == 4" class="btn btn-danger"
      [disabled]="!CheckAllowSave() || LoadingSaveGroups" data-dismiss="modal" (click)="Save()">

      <span *ngIf="!LoadingSaveGroups">{{'SaveClientGroups' | translate}}</span>
      <span *ngIf="LoadingSaveGroups">{{'LoadingSave' | translate}}</span>

    </button>

    <button type="button" class="btn btn-danger" (click)="activeModal.close('Close click')">
      {{'Close' | translate}}</button>

    <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="printPage()">{{'Print' |
      translate}}</button>
  </div>
</div>
