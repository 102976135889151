import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { BookingOrTransactionItemType } from '../../../models/enums/booking-or-transaction-item-type';
import { TransactionType } from '../../../models/enums/transaction-type';
import { SharedService } from '../../../services/other/shared.service';
import { Transaction1Service } from '../../../services/transaction/transaction.service';
import { PaymentMethodType } from 'src/app/models/enums/payment-method-type';
import { TypeThatClientsQueueSystemDependsOn } from '../../../models/enums/type_that_clients_queue_system_depends_on';
import { AccountSetup } from '../../../models/classes/account-setup/account-setup';
import { EmployeeShowType } from 'src/app/models/enums/employee-show-type';

@Component({
  selector: 'app-print-bill',
  templateUrl: './print-bill.component.html',
  styleUrls: ['./print-bill.component.scss'],
})
export class PrintBillComponent implements OnInit {

  @Input() public readonly item;
  @Input() public readonly AccountSetup: AccountSetup;
  @Input() public dirc;

  cashsaleId: number;
  private htmlRoot = this.document.documentElement;
  public printLang: string = 'ar';
  public CurrencyName: string;

  bill;
  constructor(
    private transactionService: Transaction1Service,
    public sharedService: SharedService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) {
    // translate.addLangs(['en', 'ar']);
    // translate.setDefaultLang(localStorage.getItem('lang'));
    // this.htmlRoot.setAttribute('dir', localStorage.getItem('dir'));
    // this.translate.use(localStorage.getItem('lang'));
  }

  public get employeeShowType(): typeof EmployeeShowType {
    return EmployeeShowType;
  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  // value = 'https://app.glamera.com/invoices/qr-code.pdf/';
  value = '';


  getBillItemName(TrProductService): string {

    var name = TrProductService.service
      ? ((this.printLang == "ar"
        ? TrProductService.service.NameAr
        : TrProductService.service.NameEn) +
        (this.sharedService.CurrentBranch.AllowShowOfferNameBesideItemNameInPrint &&
          TrProductService.OfferName ? (" ( " + TrProductService.OfferName + " ( " + this.translate.instant('BeforeDiscount') + " " + TrProductService.OriginalPrice.toFixed(this.sharedService.GetFractionRoundCount) + " ) " + " ) ") : ""))
      : TrProductService.product
        ? this.printLang == "ar"
          ? TrProductService.product.NameAr
          : TrProductService.product.NameEn
        : TrProductService.Package
          ? this.printLang == "ar"
            ? TrProductService.Package.NameAr
            : TrProductService.Package.NameEn
          : TrProductService.ItemType ==
            BookingOrTransactionItemType.GiftCard
            ? TrProductService.GiftCardGlobalName
            : "";


    return name;
  }

  ngOnInit(): void {
    this.cashsaleId = this.route.snapshot.params['id'];

    this.value =
      'ARRCZWF1dHkgVG91Y2ggLSBjYWlybwIJOTg0OTg0NTQ2AxQyMDIxLTEyLTE0VDExOjAzOjIzWgQGMjEwLjAwBQUyMS4wMA==';

    if (this.AccountSetup?.SelectedPrintInvoiceLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintInvoiceLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = 'en';
      // this.sharedService.SetTranslationPipeData();
    } else if (this.AccountSetup?.SelectedPrintInvoiceLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = 'ar';
      // this.sharedService.SetTranslationPipeData();
    } else if (this.AccountSetup?.SelectedPrintInvoiceLanguage === 'all') {
      console.log('all');
    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintInvoiceLanguage && this.AccountSetup?.SelectedPrintInvoiceLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintInvoiceLanguage;
    }

    this.CurrencyName =
      this.sharedService.Lang == 'ar'
        ? this.sharedService.CurrencyNameAr
        : this.sharedService.CurrencyNameEn;

  }
  PrintPage() {
    window.print();
  }

  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  public get typeThatClientsQueueSystemDependsOn(): typeof TypeThatClientsQueueSystemDependsOn {
    return TypeThatClientsQueueSystemDependsOn;
  }

  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }
  public get paymentMethodType(): typeof PaymentMethodType {
    return PaymentMethodType;
  }


}
