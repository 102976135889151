import { AccountSetupFeature } from './account-setup-feature';
import { AccountCurrency } from './account-currency';
import { Company } from '../company/company';
import { AreaOrRegion } from '../other/area-or-region';
import { WorkingHour } from '../employee/working-hour';
import { DownPaymentType } from '../../enums/down-payment-type';
import { BaseClass } from '../other/base-class';
import { DetailCode } from '../other/detail-code';
import { StateOrGovernorate } from '../other/state-or-governorate';
import { WhenDeleteWorkDoneClientItemFromTransactionForBookingPayment } from '../../enums/when-delete-work-done-client-item-from-transaction-for-booking-payment';
import { WayOFDealingWithBannedClients } from '../../enums/way-oF-dealing-with-banned-clients';
import { ServiceComponentsEffects } from '../../enums/service-components-effects';
import { ApplyTaxesWay } from '../../enums/apply-taxes-way';
import { BranchCodeSetting } from '../transactions/branch-code-setting';
import { TypeThatClientsQueueSystemDependsOn } from '../../enums/type_that_clients_queue_system_depends_on';
import { RequestsPermissionsNotificationsSoundsType } from '../../enums/requests-permissions-notifications-sounds-type';
import { EmployeeShowType } from '../../enums/employee-show-type';
import { UsersAllowedToEditShiftsBalances } from '../../enums/users-allowed-to-edit-shifts-balances';
import { ApplyWay } from '../../enums/apply-way';
import { ClientsQueueSystemType } from '../../enums/clients_queue_system_type';
import { FractionApproximationMethod } from '../../enums/fraction-approximation-method';
import { ActionWhenItemEmployeeIsTheSameAsCurrentEmployee } from '../../enums/ActionWhenItemEmployeeIsTheSameAsCurrentEmployee';
import { MedicalServicesTaxesExcludedNationality } from '../transactions/medical-services-taxes-excluded-nationality';

export class AccountSetup extends BaseClass {
  constructor() {
    super();
    this.CompanyRegionId = 0;
    this.CompanyStateId = 0;
    this.AllowTransactionVoiding = false;
    this.PctPaidForServicePerformed = 0;
    this.AccountFeatures = [new AccountSetupFeature()];
  }
  // 1.Site details
  public BusinessSize: number;
  public BusinessType: number;
  public Id: number = 0;
  public CompanyId: number;
  public Company: Company;
  public IsMainBranch: boolean;
  public RegionTimeZoneId: number;
  public RegionTimeZone: DetailCode;
  public CultureInfoId: number;
  public Culture: DetailCode;
  public SiteName: string;
  public SiteNameCulture: string;
  public GlobalName: string;
  public Address: string;
  public AddressCulture: string;
  public CountryId: number;
  public Country: DetailCode;
  public AllowUsePurchasesTaxRate: boolean;
  public NumberOfEmployeesShownInCalendarScreen: number;
  public NumberOfShownEmployeesInSalesAndBooking: number;
  public AllowEditBookingsThatHasPaymentSmallerThanBookingTotalAmount: boolean;
  public AllowUseAutomaticUpdateInBookingsCalendarScreen: boolean;
  public AllowUseAutomaticUpdateInBookingsListScreen: boolean;

  public CountryFlagPath: string;
  public CountryGlobalName: string;
  public PriceIncludeTax: boolean;
  public AllowPayTipsWithPaymentDevice: boolean;
  public BusinessTypeBackgroundPath: string;
  public FractionApproximationMethod: FractionApproximationMethod;
  public PostCode: string;
  public PhoneNumber: string;
  public MobileNumber: string;
  public Email: string;
  public AllowShowTaxRegistrationNumberInPrint: boolean;
  public ShowServiceImageInBill: boolean;
  public ShowQRImageInBill: boolean;
  public AllowUsePromoCode: boolean;

  public AllowShowOfferNameBesideItemNameInSalesScreen: boolean;
  public AllowShowOfferNameBesideItemNameInPrint: boolean;

  public AllowGenerateQRCode: boolean;
  public SelectedPrintInvoiceLanguage: string;
  public SelectedPrintEmployeeLanguage: string;

  public BookingDownPaymentHeaderTextAR: string;
  public BookingDownPaymentHeaderTextEN: string;

  public AllowTracingCommissionInCashSale: boolean;
  public AllowUseRequestsPermissionsNotificationsSounds: boolean;
  public AllowUseDiscountOnTotalNetPriceInSales: boolean;
  public RequestsPermissionsNotificationsSoundsType: RequestsPermissionsNotificationsSoundsType;
  public AccountFeatures: AccountSetupFeature[];
  public WebsiteAddress: string;
  public ManagerFirstName: string;
  public ManagerFirstNameCulture: string;
  public ManagerLastName: string;

  public AllowShowTipsPrintBill: boolean;
  public AllowShowTipsPrintEmployees: boolean;
  public AllowTheUseOfTheBookingServicesNumberingSystem: boolean;

  public SalesInvoiceHeaderTextAR: string;
  public SalesInvoiceHeaderTextEN: string;

  public SaleRefundInvoiceHeaderTextAR: string;
  public SaleRefundInvoiceHeaderTextEN: string;
  public ManagerLastNameCulture: string;
  public MakeupArtist: number;

  public ImagePath: string;
  public QRImagePath: string;

  public AllowUseFastRetailProductInSalesAndBooking: boolean;
  public AllowChangeGymMembershipClient: string;
  public AllowAutomaticAddRetailProductInBillWhenSearchResultsIsOneRetailProductOnly: boolean;

  public AllowShowStatisticsInMainPage: boolean;
  public AllowUseSalesTaxRate: boolean;
  public BusinessTypes: string;

  public BranchCodeSettings: BranchCodeSetting[];

  public UpdateOldCodesInTransactions: boolean;
  public AllowUsePaymentDevicesInSales: boolean;

  public PaymentDevicesId: number;

  public PaymentDeviceUsingWay: ApplyWay;

  public AllowShowItemsPricesInPrintEmployees: boolean;

  public AllowUseClientsQueueSystem: boolean;
  public TypeThatClientsQueueSystemDependsOn: TypeThatClientsQueueSystemDependsOn;
  public ClientsQueueSystemType: ClientsQueueSystemType;
  public AllowShowClientsQueueNumberInPrintBill: boolean;
  public AllowShowClientsQueueNumberInPrintEmployees: boolean;
  //2.company details
  public TaxRegistrationNumber: string;
  public CompanyName: string;
  public CompanyNameCulture: string;
  public CompanyAddress: string;
  public CompanyAddressCulture: string;
  public CompanyRegionId: number;
  public Regions: AreaOrRegion[];
  public CompanyStateId: number;
  public States: StateOrGovernorate[];

  public CompanyPostCode: string;
  public CompanyPhoneNumber: string;
  public CompanyMobileNumber: string;

  public CompanyEmail: string;
  public CompanyWebSiteAddress: string;
  public CompanyOwnerFirstName: string;
  public CompanyOwnerFirstNameCulture: string;
  public CompanyOwnerLastName: string;
  public CompanyOwnerLastNameCulture: string;

  //3.Opening hours
  public SunStartTime: string;
  public MonStartTime: string;
  public TuesStartTime: string;
  public WednesStartTime: string;
  public ThursStartTime: string;
  public FriStartTime: string;
  public SatStartTime: string;
  public SunEndTime: string;
  public MonEndTime: string;
  public TuesEndTime: string;
  public WednesEndTime: string;
  public ThursEndTime: string;
  public FriEndTime: string;
  public SatEndTime: string;

  public IsSunOff: boolean = false;
  public IsMonOff: boolean = false;
  public IsTuesOff: boolean = false;
  public IsWednesOff: boolean = false;
  public IsThursOff: boolean = false;
  public IsFriOff: boolean = false;
  public IsSatOff: boolean = false;
  public SpecificDays: SpecificDay[];

  public AllowUseNumberOfPrintsInBills: boolean;
  public AllowShowNumberOfPrintsInBills: boolean;

  public AllowShowBookingNotificationsInAllSystemPages: boolean;

  //4.sales settings
  public AllowTransactionVoiding: boolean;

  public AllowDeposit: boolean = false; // this field shall allow user to flag if allowing partial payment on inventories or not
  // public CurrencyId: number;
  // public Currency: Currency;
  public AccountCurrencies: AccountCurrency[];
  public EnableTradingSystem: boolean = false; //this field allow system to record selling transactions done over the center as per trading date, that opened by the store manager
  public DeclareTheRegistersBeforeTrading: boolean;
  public DeclareTheRegistersAfterTrading: boolean;

  //5.Employee Settings

  public PctPaidForServicePerformed: number;
  public PctPaidForProductSold: number;

  public AllowUseMaxNumberOfPrintsInBillsForCashier: boolean;
  public MaxNumberOfPrintsInBillsForCashier: number;


  public AllowUseMaxNumberOfPrintsInEmployeesForCashier: boolean;
  public MaxNumberOfPrintsInEmployeesForCashier: number;

  public AllowUseMaxNumberOfPrintsInItemsForCashier: boolean;
  public MaxNumberOfPrintsInItemsForCashier: number;


  //6.Loyalty Points tab
  public GiveLoyaltyDollarsPointsToYourClientsOnEveryTransaction: boolean;
  public NewClientsAreIncludedInTheLoyalitySystem: boolean;

  //////////Services
  public ServicePointsAndDollarsAreGivenForEveryXDollarSpent: number;
  public ServiceLoyaltyPointsGivenForEveryXDollarSpent: number;
  public ServiceLoyaltyMoneyGivenForEveryXDollarSpent: number;
  /////////Products:
  public ProductPointsAndDollarsAreGivenForEveryXDollarSpent: number;
  public ProductLoyaltyPointsGivenForEveryXDollarSpent: number;
  public ProductLoyaltyMoneyGivenForEveryXDollarSpent: number;
  ///////Transaction:
  public LoyaltyPointsAreGivenPerVisit: number;
  public LoyaltyMoneyAreGivenPerVisit: number;
  // public HundredPointValue: number;
  public XPointsCount: number;
  public XPointsValue: number;

  //7.Dashboard Widgets Tab

  public DashboardDisplayAll: boolean;
  public DashboardUpcomingAppointments: boolean;
  public DashboardPendingOnlineBookings: boolean;
  public DashboardSalesGraphs: boolean;
  public DashboardTasks: string;
  public DashboardWaitingLists: boolean;
  public SalesTaxRate: number;
  public AllowUseRooms: boolean;

  public AllowUseServiceImages: boolean;
  public AllowCommissionFromClients: boolean;
  public AllowSelectStartTimeOfServicesInPackage: boolean;

  public AllowPrintAfterCreateTransaction: boolean;
  public AskBeforePrint: boolean;
  public AllowRepeatServicesInBookingAndSales: boolean;
  public AllowPrintAfterCreateBooking: boolean;
  public AllowShowCommercialRecordNumberInPrint: boolean;
  public CommercialRecordNumber: string;
  public AllowChangeBillItemsEmployeesByQRCode: boolean;
  public ActionWhenItemEmployeeIsTheSameAsCurrentEmployee: ActionWhenItemEmployeeIsTheSameAsCurrentEmployee;

  public IsSmartZone: boolean;
  //8.Receipt Printing

  public PrintReceiptAdvertText: string;
  public PrintReceiptAdvertTextCulture: string;
  public PrintReceiptIncludeClientphoneNumber: boolean;
  //---
  public DoYouHaveReceiptPrinter: boolean;
  public DoYouHaveCashDrawer: boolean;
  public Longitude: number;
  public Latitude: number;
  public CityId: number;
  public AreaId: number;

  public HaseInHomeService: number;
  public ServedGender: number;
  public HasTaxiService: number;
  public WorkingHours: WorkingHour[];
  public StartDate: Date;

  public MaxNumberOfLeaveRequestPerEmployee: number;
  public MaxNumberOfHoursPerLeaveRequest: number;

  public IsMachineOrClockInOrOutAttendanceTypeTrueForMachine: boolean;

  public ServiceComponentsEffect: ServiceComponentsEffects;

  //Down Payment

  public DownPaymentType: DownPaymentType;

  public MinDownPaymentPercent: number;

  public ConvertBookingToConfirmedAutomaticallyWhenDownPaymentPaid: boolean;

  public WhenDeleteWorkDoneClientItemFromTransactionForBookingPayment: WhenDeleteWorkDoneClientItemFromTransactionForBookingPayment;

  public WayOFDealingWithBannedClients: WayOFDealingWithBannedClients;

  public AllowUseSessions: boolean;

  public AllowUseLazerSessions: boolean;
  public DoNotAllowOpenDayWithFutureDate: boolean;

  public PulsePrice: number;

  public AllowUseServiceByAreaInServices: boolean;

  public AllowUseServiceEmployeesOnlyInBookingAndSales: boolean;

  public EmployeeShowType: EmployeeShowType;

  public AllowEditShiftsBalancesAfterCloseRequest: UsersAllowedToEditShiftsBalances;
  public UsersAllowedToEditShiftsBalances: UsersAllowedToEditShiftsBalances;

  public AllowEditServiceByAreaSessionsPricesInBookingAndTransactions: boolean;

  public BusinessTypesArray: number[];

  public AllowTaxExemptionForServices: boolean;
  public AllowTaxExemptionForProducts: boolean;

  public AllowHandleWithdrawalOfConsumableQuantitiesForExpirationDatesAutomatically: boolean;

  public AllowUseTaxForProducts: boolean;
  public AllowUseTaxForServices: boolean;

  public ApplyTaxesWay: ApplyTaxesWay;

  public FactorOfActualRevenueForDoctorPulses: number;
  public FactorOfDoctorPulses: number;
  public FactorOfDoctorPulsesByArea: number;

  public AutoSelectAvailableEmployee?: boolean;
  public AllowShowClientNationalityInPrint: boolean;


  public AllowExcludeSomeNationalitiesFromPayingMedicalServicesTaxes: boolean;
  public ExcludedNationalitiesFromPayingMedicalServicesTaxes: number[];

  public MedicalServicesTaxesExcludedNationalities: MedicalServicesTaxesExcludedNationality[];

}

export class SpecificDay {
  public Id: number;
  public AccountSetupId: number;
  public accountSetup: AccountSetup;
  public Date: string;
  public StartTime: string;
  public EndTime: string;
  public Description: string;
  public IsClosed: boolean;
}
