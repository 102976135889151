import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { BookingOrTransactionItemType } from '../../../models/enums/booking-or-transaction-item-type';
import { TransactionType } from '../../../models/enums/transaction-type';
import { SharedService } from '../../../services/other/shared.service';
import { PaymentMethodType } from 'src/app/models/enums/payment-method-type';
import { TypeThatClientsQueueSystemDependsOn } from '../../../models/enums/type_that_clients_queue_system_depends_on';
import { AccountSetup } from '../../../models/classes/account-setup/account-setup';
import { EmployeeShowType } from 'src/app/models/enums/employee-show-type';

@Component({
  selector: 'app-print-bill-and-employees',
  templateUrl: './print-bill-and-employees.component.html',
  styleUrls: ['./print-bill-and-employees.component.scss'],
})
export class PrintBillAndEmployeesComponent implements OnInit {

  @Input() public readonly item;
  @Input() public readonly AccountSetup: AccountSetup;
  @Input() public dirc;
  public CurrencyName: string;
  public BillInfoUrl: string;
  public BillInfoForPackageUrl: string;
  public printLang: string = 'ar';
  public
  cashsaleId: number;
  private htmlRoot = this.document.documentElement;

  bill;
  constructor(
    public sharedService: SharedService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) {
    // translate.addLangs(['en', 'ar']);
    // translate.setDefaultLang(localStorage.getItem('lang'));
    // this.htmlRoot.setAttribute('dir', localStorage.getItem('dir'));
    // this.translate.use(localStorage.getItem('lang'));
  }

  public get employeeShowType(): typeof EmployeeShowType {
    return EmployeeShowType;
  }

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  // value = 'https://app.glamera.com/invoices/qr-code.pdf/';
  value = '';

  ngOnInit(): void {

    this.BillInfoUrl = location.origin + "/transaction/bill-info/billItemId/";
    this.BillInfoForPackageUrl = location.origin + "/transaction/bill-info/billPackageItemId/";

    this.cashsaleId = this.route.snapshot.params['id'];

    this.value =
      'ARRCZWF1dHkgVG91Y2ggLSBjYWlybwIJOTg0OTg0NTQ2AxQyMDIxLTEyLTE0VDExOjAzOjIzWgQGMjEwLjAwBQUyMS4wMA==';

    if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'en') {
      // this.translate.use('en');
      // console.log(this.AccountSetup?.SelectedPrintInvoiceLanguage)
      // this.translate.use('en');
      this.dirc = 'ltr';
      this.sharedService.TempLangKey = "en";
      this.sharedService.SetTranslationPipeData();
    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'ar') {
      // this.translate.use('ar');
      this.dirc = 'rtl';
      this.sharedService.TempLangKey = "ar";
      this.sharedService.SetTranslationPipeData();

    }
    else if (this.AccountSetup?.SelectedPrintEmployeeLanguage === 'all') {
      console.log('all')

    }

    this.printLang = this.sharedService.Lang;

    if (this.AccountSetup?.SelectedPrintInvoiceLanguage && this.AccountSetup?.SelectedPrintInvoiceLanguage != 'all') {
      this.printLang = this.AccountSetup?.SelectedPrintInvoiceLanguage;
    }

    this.CurrencyName =
      this.sharedService.Lang == 'ar'
        ? this.sharedService.CurrencyNameAr
        : this.sharedService.CurrencyNameEn;

  }
  PrintPage() {
    window.print();
  }

  public get transactionType(): typeof TransactionType {
    return TransactionType;
  }

  public get typeThatClientsQueueSystemDependsOn(): typeof TypeThatClientsQueueSystemDependsOn {
    return TypeThatClientsQueueSystemDependsOn;
  }

  public get bookingOrTransactionItemType(): typeof BookingOrTransactionItemType {
    return BookingOrTransactionItemType;
  }
  public get paymentMethodType(): typeof PaymentMethodType {
    return PaymentMethodType;
  }

  getBillItemName(TrProductService): string {

    var name = TrProductService.service
      ? ((this.printLang == "ar"
        ? TrProductService.service.NameAr
        : TrProductService.service.NameEn) +
        (this.sharedService.CurrentBranch.AllowShowOfferNameBesideItemNameInPrint &&
          TrProductService.OfferName ? (" ( " + TrProductService.OfferName + " ( " + this.translate.instant('BeforeDiscount') + " " + TrProductService.OriginalPrice.toFixed(this.sharedService.GetFractionRoundCount) + " ) " + " ) ") : ""))
      : TrProductService.product
        ? this.printLang == "ar"
          ? TrProductService.product.NameAr
          : TrProductService.product.NameEn
        : TrProductService.Package
          ? this.printLang == "ar"
            ? TrProductService.Package.NameAr
            : TrProductService.Package.NameEn
          : TrProductService.ItemType ==
            BookingOrTransactionItemType.GiftCard
            ? TrProductService.GiftCardGlobalName
            : "";


    return name;
  }

  hasNonNullEmployeeTransactionCommission(item: any): boolean {
    return item.TrProductServices.some(service => service.EmployeeTransactionCommission
      && service.EmployeeTransactionCommission.CommissionValue > 0);
  }

  getEmployeeName(employeeId: number): string {

    let employeeName = null;

    var getTransactionItem = this.item.
      TrProductServices.
      find(ti => ti.EmployeeId == employeeId);

    if (getTransactionItem) {

      employeeName = getTransactionItem.Employee ? (this.printLang == "ar" ?
        getTransactionItem.Employee?.NameAr :
        getTransactionItem.Employee?.NameEn) :
        this.translate.instant('NotFound');

    } else {

      var itemsWithPackages = this.item.TrProductServices.
        filter(tr => tr.TransactionsPackageServices &&
          tr.TransactionsPackageServices.length > 0);

      if (itemsWithPackages && itemsWithPackages.length > 0) {
        itemsWithPackages.forEach((itemWithPackages, index) => {

          var transactionsPackageServices = itemWithPackages.
            TransactionsPackageServices.
            find(ti => ti.EmployeeId == employeeId);

          if (transactionsPackageServices) {

            employeeName = transactionsPackageServices.Employee ? (this.printLang == "ar" ?
              transactionsPackageServices.Employee?.NameAr :
              transactionsPackageServices.Employee?.NameEn) :
              this.translate.instant('NotFound');
          }

        });

      }
    }

    return employeeName ?? this.translate.instant('NotFound');

  }
}
