import { Injectable } from '@angular/core';
import { BaseService, BaseServiceSZ } from '../other/baseService';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { UIStateService } from '../other/ui-state.service';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AccountSetup } from '../../models/classes/account-setup/account-setup';
import { Company } from '../../models/classes/company/company';
import { BranchesCategories } from '../../models/classes/other/branches-categories';
import { BranchesCategoryCriteria } from '../../models/search-criterias/branches-category-criteria';
import { ServiceBranchesCriteria } from '../../models/search-criterias/service-branches-criteria';
import { ExecutionResponse } from '../../models/support/execution-response';
import { SharedService } from '../other/shared.service';
import { GetCompanyReturnPolicySettingSearchCriteria } from 'src/app/models/search-criterias/get-company-return-policy-setting-search-criteria';
import { CompanyReturnPolicySetting } from 'src/app/models/classes/transactions/company-return-policy-setting';


@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {
  companyUrl: string;
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private _BaseService: BaseServiceSZ,
    private _UIStateService: UIStateService,
    private _sharedService: SharedService

  ) {
    super();
    this.companyUrl = this._sharedService.ApiUrl + 'Company';

  }

  getCompanyById(): Observable<ExecutionResponse<Company>> {

    var companyId = super.getCompanyId();
    return this.http.post<ExecutionResponse<Company>>(this.companyUrl + '/GetById', companyId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          //this._UIStateService._AppGlobals.AccountData = res.Result ;
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Company>>('getCompany id=${id}'))
      );
  }

  getCompany(): Observable<ExecutionResponse<Company>> {

    var accountSetupId = super.getAccountSetupId();
    return this.http.post<ExecutionResponse<Company>>(this.companyUrl + '/GetByAccountSetupId', accountSetupId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          //this._UIStateService._AppGlobals.AccountData = res.Result ;
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Company>>('getCompany id=${id}'))
      );
  }

  getAccountSetups(companyId): Observable<ExecutionResponse<AccountSetup[]>> {
    return this.http.post<ExecutionResponse<AccountSetup[]>>(this.companyUrl + '/getAccountSetups', companyId, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
          //this._UIStateService._AppGlobals.AccountData = res.Result ;
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<AccountSetup[]>>('getCompany id=${id}'))
      );
  }




  getBranches(username: string): Observable<ExecutionResponse<AccountSetup[]>> {
    return this.http.post<ExecutionResponse<AccountSetup[]>>(this.companyUrl + '/getBranches', '"' + username + '"', super.getHeaders())
      .pipe(
        tap(res => {
          ;
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<AccountSetup[]>>('getAccountSetup id=${id}'))
      );
  }

  /** PUT: update the category on the server */
  updateCompany(company: Company): Observable<ExecutionResponse<Company>> {
    return this.http.post<ExecutionResponse<Company>>(this.companyUrl + '/Update', company, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.log('added category w/ id=${category.Id}');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<Company>>('addCompany id=${id}'))
      );
  }


  getAccountsWithService(serviceBranchesCriteria: ServiceBranchesCriteria): Observable<ExecutionResponse<any[]>> {

    serviceBranchesCriteria.CompanyId = +super.getCompanyId();
    return this.http.post<ExecutionResponse<any[]>>(this.companyUrl + '/GetAccountsWithService', serviceBranchesCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<any[]>>('getCompany id=${id}'))
      );
  }

  getAccountsWithCategories(branchesCategoryCriteria: BranchesCategoryCriteria): Observable<ExecutionResponse<any[]>> {

    branchesCategoryCriteria.CompanyId = +super.getCompanyId();
    return this.http.post<ExecutionResponse<BranchesCategories[]>>(this.companyUrl + '/GetAccountWithCategories', branchesCategoryCriteria, super.getHeaders())
      .pipe(
        tap(res => {
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<BranchesCategories[]>>('getCompany id=${id}'))
      );
  }
  getCompanyReturnPolicySetting(criteria: GetCompanyReturnPolicySettingSearchCriteria): Observable<ExecutionResponse<CompanyReturnPolicySetting>> {

    return this.http.get<ExecutionResponse<CompanyReturnPolicySetting>>(this.companyUrl + '/GetCompanyReturnPolicySetting', { headers: this._sharedService.getHeaders().headers, params: this._sharedService.ToHttpParams(criteria) })
      .pipe(
        tap(res => {
          this._BaseService.log('GetCompanyReturnPolicySetting');
          this._BaseService.ValidationResult(res);
        }),

        catchError(this._BaseService.handleError<ExecutionResponse<CompanyReturnPolicySetting>>('GetCompanyReturnPolicySetting'))
      );
  }
}
