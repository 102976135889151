import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExecutionResponse } from '../../../models/support/execution-response';
import { HttpBaseService } from '../../../services/other/httpbase.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/services/other/shared.service';
import { tap, catchError } from 'rxjs/operators';
import { Employee } from '../../bookings/models/employee';
import { BaseServiceSZ } from 'src/app/services/other/baseService';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {

  private employeesUrl

  constructor(private httpBaseService: HttpBaseService,
    private http: HttpClient,
    private _BaseService: BaseServiceSZ,
    private sharedService: SharedService) {
    this.employeesUrl = this.sharedService.ApiUrl + 'Employee';
  }

  /*========= Employees  ================================================*/

  GetEmployees(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Get', body);
  }
  GenerateEmployeeCode(): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/GenerateEmployeeCode', null);
  }
  CreateEmployees(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Create', body);
  }
  UpdateEmployees(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Update', body);
  }
  DeleteEmployees(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Delete', body);
  }
  IEmployees(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/Delete', body);
  }

  GenerateFingerprintCode(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Employee/GenerateFingerprintCode', body);
  }
  /*========= Commissions  ================================================*/
  GetCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionSettingDetail/Get', body);
  }
  CreateCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionSettingDetail/Create', body);
  }
  UpdateCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionSettingDetail/Update', body);
  }
  DeleteCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionSettingDetail/Delete', body);
  }
  /*========= PayrollDates  ================================================*/
  GetPayrollDates(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPayrollDates/Get', body);
  }
  CreatePayrollDates(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPayrollDates/Create', body);
  }
  UpdatePayrollDates(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPayrollDates/Update', body);
  }
  DeletePayrollDates(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPayrollDates/delete', body);
  }
  /*========= HrFingerprintSetup  ================================================*/
  GetHrFingerprintSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrFingerprintSetup/Get', body);
  }
  CreateHrFingerprintSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrFingerprintSetup/Create', body);
  }
  UpdateHrFingerprintSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrFingerprintSetup/Update', body);
  }
  DeleteHrFingerprintSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrFingerprintSetup/delete', body);
  }
  GetByAccountSetupId(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Company/GetByAccountSetupId', body);
  }


  /*========== AttendMethod ===============================================*/
  GetAttendMethodMaster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMaster/Get', body);
  }
  GetAttendMethodMasterDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMasterDetail/Get', body);
  }
  DeleteAttendMethodMaster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMaster/Delete', body);
  }
  CreateAttendMethodMaster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMaster/Create', body);
  }
  CreateAttendMethodMasterDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMasterDetail/Create', body);
  }
  GetByIdAttendMethodMasterDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMasterDetail/Get', body);
  }
  UpdateAttendMethodMaster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMaster/Update', body);
  }
  UpdateAttendMethodMasterDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendMethodMasterDetail/Update', body);
  }

  getEmployee(id: number): Observable<ExecutionResponse<Employee>> {
    //  getEmployee(id: String): ExecutionResponse<Employee> {
    //const url = '${this.employeesUrl}/${id}';
    return this.http.post<ExecutionResponse<Employee>>(this.employeesUrl + '/GetById', id, this.sharedService.getHeaders())
      .pipe(
        tap(_ => this._BaseService.log('fetched employee id=${id}')),
        catchError(this._BaseService.handleError<ExecutionResponse<Employee>>('getEmployee id=${id}'))
      );
  }

  /*========== Departments ===============================================*/
  GetAllDepartments(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('department/GetAllDepartments', body);
  }
  CreateDepartments(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('department/Create', body);
  }
  UpdateDepartments(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('department/Update', body);
  }
  DeleteDepartments(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Department/Delete', body);
  }
  /*========== Positions ===============================================*/
  GetAllPositions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('employeeposition/GetAllPositions', body);
  }
  CreatePositions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('employeeposition/Create', body);
  }
  UpdatePositions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('employeeposition/Update', body);
  }
  DeletePositions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('employeeposition/Delete', body);
  }

  /*========== Basic Salary Items===============================================*/
  GetBasicSalaryItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalItemsMaster/Get', body);
  }
  CreateBasicSalaryItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalItemsMaster/Create', body);
  }
  UpdateBasicSalaryItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalItemsMaster/Update', body);
  }
  DeleteSalaryItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalItemsMaster/Delete', body);
  }
  /*========== Generator Salary Items HRSalryGeneratorOrch===============================================*/
  GetGeneratorSalaryItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HRSalaryGenerator/Get', body);
  }
  CreateGeneratorSalaryItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HRSalaryGenerator/CreateList', body);
  }
  UpdateGeneratorSalaryItems(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HRSalaryGenerator/Update', body);
  }
  DeleteGenerator(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HRSalaryGenerator/Delete', body);
  }
  /*========== HrEmpBasicSalary =============================================*/
  GetHrEmpBasicSalary(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpBasicSalary/Get', body);
  }
  GetHrEmpById(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpBasicSalary/GetById', body);
  }
  CreateHrEmpBasicSalary(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpBasicSalary/Create', body);
  }
  UpdateHrEmpBasicSalary(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpBasicSalary/Update', body);
  }
  /*========== GetSalaries =============================================*/
  GetSalaries(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpBasicSalary/Get', body);
  }
  getByIdAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AccountSetup/getById', body);
  }
  // CreateHrEmpBasicSalary(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('HrEmpBasicSalary/Create', body)
  // }
  // UpdateHrEmpBasicSalary(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('HrEmpBasicSalary/Update', body)
  // }

  // HrEmpBasicSalary/SaveChanges
  // [{"Id":0,"EmpId":13682,"HrSalItemsMasterId":1018,"HrSalItemsMasterValue":1500,"IsActive":true,"StartDate":"2020-07-14T00:00:00.000Z","MainItem":1,"PayrollType":1,"AccountSetupId":7299},{"Id":0,"EmpId":13682,"HrSalItemsMasterId":1021,"HrSalItemsMasterValue":100,"IsActive":true,"StartDate":"2020-07-14T00:00:00.000Z","EndDate":"2020-07-17T00:00:00.000Z","MainItem":1,"PayrollType":1,"AccountSetupId":7299}]
  /*=================================================================*/
  GetDropdowns(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SystemCodes/GetDropdowns', body);
  }

  /*========= hrEmpAttend  ================================================*/
  GethrEmpAttend(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmpAttend/Get', body);
  }
  CreatehrEmpAttend(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmpAttend/Create', body);
  }
  UpdatehrEmpAttend(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmpAttend/Update', body);
  }
  DeletehrEmpAttend(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmpAttend/Delete', body);
  }
  /*========= hrVacationRequest  ================================================*/
  GethrVacationRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationRequest/Get', body);
  }
  CreatehrVacationRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationRequest/Create', body);
  }
  UpdatehrVacationRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationRequest/Update', body);
  }
  DeletehrVacationRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationRequest/Delete', body);
  }

  GetVacationBalnce(employeeId): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationRequest/GetVacationBalnce', employeeId);
  }

  /*========= CommissionAccount  ================================================*/
  GetCommissionAccount(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'BookingService/GetEmployeeBookingService',
      body
    );
  }
  CreateCommissionAccount(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Create', body);
  }
  UpdateCommissionAccount(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Update', body);
  }
  DeleteCommissionAccount(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('Transactions/Delete', body);
  }

  /*========= Upload Files  ================================================*/
  UploadEmployeeXcelFile(body: any): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmpAttend/UploadFiles', body);
  }

  /*========= CommissionAccount  ================================================*/
  GetCommissionTrans(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeCommission/Get', body);
  }
  CreateCommissionTrans(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeCommission/Create', body);
  }
  CreateBulkCommissionTrans(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeCommission/BulkCreate', body);
  }
  UpdateCommissionTrans(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeCommission/Update', body);
  }
  DeleteCommissionTrans(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeCommission/Delete', body);
  }

  /*========= CommissionAccount  ================================================*/
  getVacationName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationName/Get', body);
  }
  createVacationName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationName/Create', body);
  }
  updateVacationName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationName/Update', body);
  }
  // DeleteCommissionTrans(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('VacationName/Delete', body);
  // }
  deleteVacationName(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationName/Delete', body);
  }

  /*======== Absent request =====================================================*/
  GetAbsentEmp(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAbsentRequest/Get', body);
  }
  CreateAbsentEmp(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAbsentRequest/Create', body);
  }
  UpdateAbsentEmp(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAbsentRequest/Update', body);
  }
  DeleteAbsentEmp(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrAbsentRequest/Delete', body);
  }

  /*=============================RequestEmployeePermission============================*/
  getRequestEmployeePermissions(body): Observable<ExecutionResponse<any>> {


    return this.httpBaseService.Post('RequestEmployeePermissions/Get', body);
  }
  createRequestEmployeePermissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RequestEmployeePermissions/Create', body);
  }
  updateRequestEmployeePermissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RequestEmployeePermissions/Update', body);
  }
  DeleteRequestEmployeePermissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RequestEmployeePermissions/Delete', body);
  }

  /*=============================HrSalaryGenerators============================*/
  getHrSalaryGenerator(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HRSalaryGenerator/Get', body);
  }
  createHrSalaryGenerator(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryGenerator/Create', body);
  }
  updateHrSalaryGenerator(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryGenerator/Update', body);
  }

  DeleteHrSalaryGenerator(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryGenerator/Delete', body);
  }
  getHrEmployeeSalaryLog(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeSalaryLog/Get', body);
  }

  generateHrSalaryGenerator(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HRSalaryGenerator/Generate', body);
  }
  GetHrEmployeeGeneratedSalary(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeGeneratedSalary/Get', body);
  }
  updateHrEmployeeGeneratedSalary(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeGeneratedSalary/update', body);
  }
  /*=============================HrPermissionslookup============================*/
  getHrPermissionsLookup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPermissionsLookup/Get', body);
  }
  getPreviousPermissionsForEmployee(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RequestEmployeePermissions/Get', body);
  }
  /*=============================HrViolations============================*/
  getHrViolations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrViolations/Get', body);
  }
  createHrViolations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrViolations/Create', body);
  }
  updateHrViolations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrViolations/Update', body);
  }
  DeleteHrViolations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrViolations/Delete', body);
  }
  /*=============================HrPenalities============================*/
  getHrPenalities(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalities/Get', body);
  }
  createHrpenalties(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalities/Create', body);
  }
  updateHrpenalties(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalities/Update', body);
  }
  DeleteHrpenalties(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalities/Delete', body);
  }
  /*=============================HrSalaryGetail============================*/
  GetHrSalaryDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryDetail/Get', body);
  }
  GetHrSalaryDetailById(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryDetail/GetById', body);
  }
  CreateHrSalaryDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryDetail/Create', body);
  }
  UpdateHrSalaryDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryDetail/Update', body);
  }
  DeleteHrSalaryDetail(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrSalaryDetail/Delete', body);
  }
  /*=============================SanctionsRegulations============================*/
  getSanctionsRegulations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SanctionsRegulations/Get', body);
  }
  getbyIdSanctionsRegulations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SanctionsRegulations/GetById', body);
  }
  createSanctionsRegulations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SanctionsRegulations/Create', body);
  }
  updateSanctionsRegulations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SanctionsRegulations/Update', body);
  }
  DeleteSanctionsRegulations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SanctionsRegulations/Delete', body);
  }
  /*=============================SanctionsRegulationsDetailes============================*/
  getSanctionsRegulationsDetailes(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('SanctionsRegulationsDetails/Get', body);
  }
  createSanctionsRegulationsDetailes(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'SanctionsRegulationsDetails/Create',
      body
    );
  }
  updateSanctionsRegulationsDetailes(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'SanctionsRegulationsDetails/Update',
      body
    );
  }
  DeleteSanctionsRegulationsDetailes(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'SanctionsRegulationsDetails/Delete',
      body
    );
  }

  MasterUpdateSanctionsRegulations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'SanctionsRegulationsDetails/MasterUpdate',
      body
    );
  }
  /*=============================HrPenalityRequest============================*/
  // getPenalityRequest(body): Observable<ExecutionResponse<any>> {
  //   return this.httpBaseService.Post('HrPenalityRequest/Get', body);
  // }
  getPenalityRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalityRequest/Get', body);
  }
  createPenalityRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalityRequest/Create', body);
  }
  updatePenalityRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalityRequest/Update', body);
  }
  DeletePenalityRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrPenalityRequest/Delete', body);
  }

  /*=============================EmployeeEndService============================*/
  getEmployeeEndService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmployeeEndService/Get', body);
  }
  createEmployeeEndService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmployeeEndService/Create', body);
  }
  updateEmployeeEndService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmployeeEndService/Update', body);
  }
  DeleteEmployeeEndService(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmployeeEndService/Delete', body);
  }
  /*=============================AttendanceEffects============================*/
  getAttendanceEffects(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendanceEffects/Get', body);
  }
  createAttendanceEffects(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendanceEffects/CreateList', body);
  }
  updateAttendanceEffects(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendanceEffects/Update', body);
  }
  DeleteAttendanceEffects(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('AttendanceEffects/Delete', body);
  }
  /*=============================HrExperieances============================*/
  getHrExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrExperiences/Get', body);
  }
  createHrExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrExperiences/Create', body);
  }
  updateHrExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrExperiences/Update', body);
  }
  DeleteHrExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrExperiences/Delete', body);
  }
  /*=============================HrEndServiceReason============================*/
  getHrEndServiceResons(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEndServiceResons/Get', body);
  }
  createHrEndServiceResons(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEndServiceResons/Create', body);
  }
  updateHrEndServiceResons(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEndServiceResons/Update', body);
  }
  DeleteHrEndServiceResons(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEndServiceResons/Delete', body);
  }
  /*=============================HrQualificationscategories============================*/
  getHrQualificationscategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualificationscategories/Get', body);
  }
  createHrQualificationscategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualificationscategories/Create', body);
  }
  updateHrQualificationscategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualificationscategories/Update', body);
  }
  DeleteHrQualificationscategories(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualificationscategories/Delete', body);
  }
  /*=============================HrQualifications============================*/
  getHrQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualifications/Get', body);
  }
  createHrQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualifications/Create', body);
  }
  updateHrQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualifications/Update', body);
  }
  DeleteHrQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrQualifications/Delete', body);
  }
  /*=============================HrColleges============================*/
  getHrColleges(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrColleges/Get', body);
  }
  createHrColleges(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrColleges/Create', body);
  }
  updateHrColleges(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrColleges/Update', body);
  }
  DeleteHrColleges(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrColleges/Delete', body);
  }
  /*=============================HrUniversities============================*/
  getHrUniversities(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrUniversities/Get', body);
  }
  createHrUniversities(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrUniversities/Create', body);
  }
  updateHrUniversities(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrUniversities/Update', body);
  }
  DeleteHrUniversities(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrUniversities/Delete', body);
  }
  /*=============================OfficalVacations============================*/
  getOfficialVacations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OfficialVacations/Get', body);
  }
  createOfficialVacations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OfficialVacations/Create', body);
  }
  updateOfficialVacations(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OfficialVacations/Update', body);
  }
  DeleteOfficialVacationss(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OfficialVacations/Delete', body);
  }
  /*=============================AnnualOpeningBalancesForVacations============================*/
  getAnnualOpeningBalancesForVacations(
    body
  ): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'AnnualOpeningBalancesForVacations/Get',
      body
    );
  }
  createAnnualOpeningBalancesForVacations(
    body
  ): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'AnnualOpeningBalancesForVacations/Create',
      body
    );
  }
  updateAnnualOpeningBalancesForVacations(
    body
  ): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'AnnualOpeningBalancesForVacations/Update',
      body
    );
  }
  deleteAnnualOpeningBalancesForVacations(
    body
  ): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(
      'AnnualOpeningBalancesForVacations/Delete',
      body
    );
  }
  /*=============================HrEmpQualifications============================*/
  getHrEmpQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpQualifications/Get', body);
  }


  createHrEmpQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpQualifications/Create', body);
  }
  updateHrEmpQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpQualifications/Update', body);
  }
  deleteHrEmpQualifications(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpQualifications/Delete', body);
  }
  /*=============================HrEmpExperience============================*/
  getHrEmpExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpExperience/Get', body);
  }
  createHrEmpExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpExperience/Create', body);
  }
  updateHrEmpExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpExperience/Update', body);
  }
  deleteHrEmpExperiences(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmpExperience/Delete', body);
  }
  /*=============================HrEmployeeRoaster============================*/
  getHrEmployeeRoaster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeRoaster/Get', body);
  }
  updateHrEmployeeRoaster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeRoaster/MasterUpdate', body);
  }
  createHrEmployeeRoaster(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrEmployeeRoaster/Create', body);
  }

  // =============================EmployeeAccountSetup========================

  GetEmployeeAccountSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmployeeAccountSetup/Get', body);
  }

  //=============================Commissionchips=============================
  GetCommissionChips(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionChips/Get', body);
  }
  GetCommissionOverTime(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OverTimeCommission/Get', body);
  }
  CreateCommissionChips(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionChips/Create', body);
  }
  UpdateCommissionChips(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionChips/Update', body);
  }

  DeleteCommissionChips(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrCommissionChips/Delete', body);
  }

  //=============================OverTimeCommission=============================
  GetOverTimeCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OverTimeCommission/Get', body);
  }
  createOverTimeCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OverTimeCommission/create', body);
  }

  UpdateOverTimeCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OverTimeCommission/Update', body);
  }

  DeleteOverTimeCommissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('OverTimeCommission/Delete', body);
  }
  //============================= HrFingerprintSetup =============================
  HrFingerprintSetup(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrFingerprintSetup/GetById', body);
  }
  //============================= SetStatusVacationRequest =============================
  SetStatusVacationRequest(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('VacationRequest/SetStatus', body);
  }
  //============================= SetStatusRequestEmployeePermissions =============================
  SetStatusRequestEmployeePermissions(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('RequestEmployeePermissions/SetStatus', body);
  }

  //export attendance
  public ExportAttendance(criteria: any) {

    if (criteria.from == undefined || criteria.from == null) {
      return this.http.get(`${this.sharedService.ApiUrl}EmpAttend/ExportEmployeesAttendance?AccountSetupId=${this.sharedService.AccountSetupId}&FreeText=${criteria.FreeText}&To=${criteria.To}&From=${criteria.From}&SelectByMonth=${criteria.SelectByMonth}`, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.sharedService.Token,
          'Content-Type': 'application/octet-stream',
        }), responseType: 'blob',
      }).pipe(
        tap(
          data => console.log('You received data'),
          error => console.log(error)
        )
      );
    }
    else {

      return this.http.get(`${this.sharedService.ApiUrl}EmpAttend/ExportEmployeesAttendance?AccountSetupId=${this.sharedService.AccountSetupId}&FreeText=${criteria.FreeText}&To=${criteria.To}&From=${criteria.From}`, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + this.sharedService.Token,
          'Content-Type': 'application/octet-stream',
        }), responseType: 'blob',
      }).pipe(
        tap(
          data => console.log('You received data'),
          error => console.log(error)
        )
      );
    }

  }
  /*========= HrBranchYear  ================================================*/
  GetHrBranchYear(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/Get', body)
  }
  GetByIdHrBranchYear(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('HrBranchYear/GetById', body)
  }
  GetEmployeeBranchTransferLog(body): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post('EmployeeBranchTransferLog/Get', body)
  }
  UpdateEmployeeBranchTransferLog(body, id: number): Observable<ExecutionResponse<any>> {
    return this.httpBaseService.Post(`EmployeeBranchTransferLog/Update?Id=${id}`, body)
  }

  ExportSalaries(model: any) {

    return this.http.get(`${this.sharedService.ApiUrl}HrEmployeeSalaryLog/ExportSalaries?AccountSetupId=${this.sharedService.AccountSetupId}&YearId=${model.YearId}&MonthId=${model.MonthId}&DepartmentId=${model.DepartmentId}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.sharedService.Token,
        'Content-Type': 'application/octet-stream',
      }), responseType: 'blob',
    }).pipe(
      tap(
        data => console.log('You received data'),
        error => console.log(error)
      )
    );
  }
  
}




